import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Table, Tbody, Td, Thead, Tr, useDisclosure, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { EditFacilityAdditionModal } from './EditFacilityAdditionModal';
import { FacilityAdditionTableRow } from './FacilityAdditionTableRow';
export const FacilityAdditionTable = ({ facility, canEdit, orgIdentity, }) => {
    const [facilityAdditionToEdit, setFacilityAdditionToEdit] = useState(undefined);
    const [facilityAdditions, setFacilityAdditions] = useState([]);
    useEffect(() => {
        var _a;
        setFacilityAdditions((_a = facility.additions) !== null && _a !== void 0 ? _a : []);
    }, [facility]);
    const { isOpen: isOpenEditFacilityAddition, onOpen: onOpenEditFacilityAddition, onClose: onCloseEditFacilityAddition, } = useDisclosure();
    const renderRows = () => {
        return (_jsxs(_Fragment, { children: [_jsxs(Tr, { h: '50px', boxSizing: 'border-box', children: [_jsx(Td, { py: 0, children: facility.yearBuilt }), _jsx(Td, { py: 0, children: "Original" }), _jsx(Td, { py: 0, children: facility.originalSquareFootage }), _jsx(Td, { py: 0 })] }), facilityAdditions &&
                    facilityAdditions.length > 0 &&
                    facilityAdditions.map((addition, i) => {
                        return (_jsx(FacilityAdditionTableRow, { facilityAddition: addition, onOpenEditFacilityAddition: onOpenEditFacilityAddition, setFacilityAdditionToEdit: setFacilityAdditionToEdit, canEdit: canEdit }, `${addition} ${i}`));
                    })] }));
    };
    return (_jsxs(Stack, { children: [_jsxs(Table, { bg: 'rgba(251, 218, 189, 0.15)', borderLeft: '2px solid #F6862A', children: [_jsx(Thead, { children: _jsxs(Tr, { fontWeight: '500', color: '#003B5D', children: [_jsx(Td, { children: "Year of construction" }), _jsx(Td, { children: "Name" }), _jsx(Td, { children: "Square footage" }), _jsx(Td, {})] }) }), _jsx(Tbody, { children: renderRows() })] }), facilityAdditionToEdit && (_jsx(EditFacilityAdditionModal, { facilityAdditionToEdit: facilityAdditionToEdit, isOpen: isOpenEditFacilityAddition, onClose: () => {
                    onCloseEditFacilityAddition();
                    setFacilityAdditionToEdit(undefined);
                }, orgIdentity: orgIdentity }))] }));
};
