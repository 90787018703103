import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { SystemRole } from '@frontend/domain/models/Roles';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from '../../sharedComponents/DeleteModal';
import { deleteDescription, useArchiveOrg } from '../../utils/handleDelete';
export const SaveNew = ({ org, editMode, isSubmitting, currentUser, }) => {
    const handleArchiveOrg = useArchiveOrg();
    const navigate = useNavigate();
    useEffect(() => { }, [editMode]);
    const { isOpen: isOpenOrgDelete, onOpen: onOpenOrgDelete, onClose: onCloseOrgDelete, } = useDisclosure();
    const onEnd = () => {
        onCloseOrgDelete();
        navigate(AppRoutes.landingPage());
    };
    return (_jsxs(_Fragment, { children: [_jsx(HStack, { children: editMode && (_jsxs(_Fragment, { children: [_jsx(Button, { type: 'submit', variant: variants.editBtn, w: 'none', isDisabled: isSubmitting ? true : false, _hover: { variant: variants.secDarkGrayBtn }, children: "Save changes" }), currentUser &&
                            currentUser.systemRole ===
                                SystemRole.SUPER_ADMINISTRATOR &&
                            !window.location.href.includes(AppRoutes.createOrganization()) && (_jsx(Button, { variant: variants.transDelBtn, onClick: () => {
                                onOpenOrgDelete();
                            }, children: "Archive organization" }))] })) }), _jsx(DeleteModal, { isOpen: isOpenOrgDelete, onClose: onCloseOrgDelete, deleteItem: () => {
                    if (org) {
                        handleArchiveOrg(org, onEnd);
                    }
                }, title: 'Archive Organization', description: deleteDescription((org === null || org === void 0 ? void 0 : org.name) || 'Organization', 'archive'), buttonText: 'Archive' })] }));
};
