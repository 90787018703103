import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { SettingsPageMode } from '@frontend/domain/models/Settings/SettingsInterfaces';
export const SettingsSaveCancelBtnGroup = ({ btnLabel, validationError, data, onSave, onCancel, }) => {
    return (_jsxs(ButtonGroup, { size: 'sm', isAttached: true, variant: 'outline', alignItems: 'center', marginLeft: 'auto', display: 'flex', children: [_jsx(IconButton, { "aria-label": `Save ${btnLabel}`, icon: _jsx(CheckIcon, {}), isDisabled: validationError !== undefined, onClick: () => {
                    if (data) {
                        onSave(data);
                    }
                } }), _jsx(IconButton, { "aria-label": `Cancel upsert ${btnLabel}`, icon: _jsx(CloseIcon, {}), onClick: () => onCancel(SettingsPageMode.VIEW) })] }));
};
