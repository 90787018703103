import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text } from '@chakra-ui/react';
import { Form, } from '@frontend/design-system/components/FormModal/FormModal';
import { OnHoverWrapper } from '@frontend/design-system/components/Inputs/OnHoverWrapper';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { SettingsEditDeleteBtnGroup } from './SettingsEditDeleteBtnGroup';
import { SettingsInput } from './SettingsInput';
import { SettingsSaveCancelBtnGroup } from './SettingsSaveCancelBtnGroup';
export const ProjectTypeInput = ({ canEdit, pageMode, originalData, onDelete, validateInput, onSave, setPageMode, options, }) => {
    const [hoverMode, setHoverMode] = useState(false);
    const [validationError, setValidationError] = useState(undefined);
    const [projectTypeData, setProjectTypeData] = useState(originalData);
    const [isSelected, setIsSelected] = useState(false);
    const PROJECT_TYPE_NAME_ERROR_MSG = 'Project Type name must be unique';
    const defaultAlgorithm = Number(options[0].value);
    const onInputChange = (e) => {
        validateInput(e.target.value, () => setValidationError(PROJECT_TYPE_NAME_ERROR_MSG), () => setValidationError(undefined));
        setProjectTypeData((prevData) => (Object.assign(Object.assign({}, prevData), { name: e.target.value, algorithmId: (prevData === null || prevData === void 0 ? void 0 : prevData.algorithmId) || defaultAlgorithm })));
    };
    const onInputSelect = (algorithmIdentity) => {
        setProjectTypeData((prevData) => (Object.assign(Object.assign({}, prevData), { name: (prevData === null || prevData === void 0 ? void 0 : prevData.name) || '', algorithmId: Number(algorithmIdentity) })));
    };
    const onEdit = () => {
        setIsSelected(true);
        setPageMode(SettingsPageMode.EDIT);
    };
    const onCancel = () => {
        setPageMode(SettingsPageMode.VIEW);
        setProjectTypeData(originalData);
        setValidationError(undefined);
        setIsSelected(false);
    };
    const getAlgorithmLabel = () => {
        const algorithmOption = options.find((option) => option.value === (projectTypeData === null || projectTypeData === void 0 ? void 0 : projectTypeData.algorithmId));
        return (algorithmOption === null || algorithmOption === void 0 ? void 0 : algorithmOption.label) || '';
    };
    return (_jsxs(Flex, { flexDirection: 'column', children: [_jsxs(OnHoverWrapper, { canEdit: canEdit && pageMode === SettingsPageMode.VIEW, setHoverMode: setHoverMode, customStyles: { justifyContent: 'normal', gap: '0' }, children: [(pageMode === SettingsPageMode.EDIT && isSelected) ||
                        (pageMode === SettingsPageMode.ADD_NEW && !originalData) ? (_jsxs(_Fragment, { children: [_jsx(SettingsInput, { validationError: validationError, placeholder: 'Project Type', data: projectTypeData ? projectTypeData.name : '', onChange: onInputChange, characterLimit: 30, customStyles: {
                                    width: '33.3%',
                                    marginRight: '5%',
                                } }), _jsx(Box, { w: 'fit-content', children: _jsx(Form.Dropdown, { label: '', selectFrom: options, value: (projectTypeData === null || projectTypeData === void 0 ? void 0 : projectTypeData.algorithmId.toString()) ||
                                        defaultAlgorithm.toString(), onChange: (e) => onInputSelect(e.target.value) }) }), _jsx(SettingsSaveCancelBtnGroup, { btnLabel: 'Project Type', validationError: validationError, data: projectTypeData, onSave: () => {
                                    if (projectTypeData) {
                                        onSave(projectTypeData).then((response) => {
                                            if (response) {
                                                setIsSelected(false);
                                            }
                                        });
                                    }
                                }, onCancel: onCancel })] })) : (_jsxs(_Fragment, { children: [_jsx(Text, { w: '33.3%', marginRight: '5%', children: projectTypeData === null || projectTypeData === void 0 ? void 0 : projectTypeData.name }), _jsx(Text, { w: '33.3%', children: getAlgorithmLabel() })] })), hoverMode &&
                        originalData &&
                        pageMode === SettingsPageMode.VIEW &&
                        canEdit && (_jsx(SettingsEditDeleteBtnGroup, { btnLabel: 'Project Type', data: originalData, onEdit: onEdit, onDelete: onDelete, disableDelete: originalData.isInUse }))] }), validationError && (_jsx(Text, { mt: '5px', color: 'red', fontSize: '12px', children: validationError }))] }));
};
