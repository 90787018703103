import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDependencies } from '../Dependencies/DependenciesContext';
import { FeatureFlagContext } from './FeatureFlagContext';
export function FeatureFlagProvider({ children, }) {
    const { mysiteIQApi } = useDependencies();
    const [isLoaded, setIsLoaded] = useState(false);
    const [turnedOn, setFlagsTurnedOn] = useState(undefined);
    useEffect(() => {
        if (isLoaded === false) {
            mysiteIQApi.default.getAllFeatureFlags().then((featureFlags) => {
                const flagsTurnedOn = featureFlags.flags
                    .filter((flag) => flag.isEnabled)
                    .map((flag) => flag.flag);
                setFlagsTurnedOn(flagsTurnedOn);
                setIsLoaded(true);
            });
        }
    }, []);
    return (_jsx(FeatureFlagContext.Provider, { value: {
            isLoaded,
            turnedOn: turnedOn,
        }, children: children }));
}
