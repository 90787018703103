// src/mocks/handlers.js
import { rest } from 'msw';
import { defaultFacilityAddition } from '../testObjects';
import { environment } from '@/config/environment';

// Example mock data for MockServiceWorkerExample.test.js
export const handlers = [
	rest.get(
		`${environment.apiBase}/facilities/*/additions`,
		(req, res, ctx) => {
			return res(ctx.json([defaultFacilityAddition]));
		}
	),
	rest.delete(
		`${environment.apiBase}/organization/phototags/:tagIdentity`,
		(req, res, ctx) => {
			const { tagIdentity } = req.params;
			return res(ctx.json(tagIdentity));
		}
	),
];
