import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Td, useDisclosure } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { UserManagementContext } from '@frontend/domain/contexts/Authorization/Roles';
import { SystemRole } from '@frontend/domain/models/Roles';
import { useState } from 'react';
import { SystemRoleEnum, systemRolePriorityList, } from '../../../objects/UserData';
import { DeleteModal } from '../../../sharedComponents/DeleteModal';
import { deleteDescription, deleteUserTitle, useDeleteUser, } from '../../../utils/handleDelete';
import { canDeleteUser, toSysRoleString } from '../../../utils/userRoleUtils';
import ActionsBodyUserModal from '../../OrganizationDetails/Users/ActionsBodyUserModal';
import InvitationSentModal from '../InvitationSentModal';
import ResendInvitationModal from '../ResendInvitationModal';
import { TableType } from '../SystemAdminPage';
import { SystemAdminRow } from '../Table/SystemAdminRow';
import { SystemAdminTable } from '../Table/SystemAdminTable';
import { AddAdminModal } from './AddAdminModal';
import { EditAdminModal } from './EditAdminModal';
export const SystemAdmin = ({ currentUser, users, onUserUpdate, canResendInvite, }) => {
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const canResendInvitation = canResendInvite(UserManagementContext.ADMIN);
    const systemAdminTableHeaders = ['Role', 'Name', 'Title', 'Email'];
    const handleDeleteUser = useDeleteUser();
    const { isOpen: isOpenResendInvitation, onClose: onCloseResendInvitation } = useDisclosure({ defaultIsOpen: true });
    const { isOpen: isOpenInvitationSent, onOpen: onOpenInvitationSent, onClose: onCloseInvitationSent, } = useDisclosure();
    const { isOpen: isOpenAddUser, onOpen: onOpenAddUser, onClose: onCloseAddUser, } = useDisclosure();
    const { isOpen: isOpenEditUser, onOpen: onOpenEditUser, onClose: onCloseEditUser, } = useDisclosure();
    const { isOpen: isOpenDeleteUser, onOpen: onOpenDeleteUser, onClose: onCloseDeleteUser, } = useDisclosure();
    const onEnd = () => {
        onCloseDeleteUser();
        setSelectedUser(undefined);
        onUserUpdate();
    };
    const renderRows = () => {
        if (users) {
            users.sort((a, b) => systemRolePriorityList.indexOf(a.systemRole || SystemRoleEnum.USER) -
                systemRolePriorityList.indexOf(b.systemRole || SystemRoleEnum.USER));
            return (_jsx(_Fragment, { children: users.map((user, i) => {
                    const role = toSysRoleString(user.systemRole);
                    const canDelete = canDeleteUser(currentUser, user);
                    return (_jsx(SystemAdminRow, { popoverMenu: _jsx(ActionsBodyUserModal, { user: user, canDelete: canDelete, onOpenAddUser: onOpenEditUser, canResendInvitation: canResendInvitation, setIsLoading: setIsLoading, onOpenInvitationSent: onOpenInvitationSent, onCloseInvitationSent: onCloseInvitationSent, onCloseResendInvitation: onCloseResendInvitation, setUser: setSelectedUser, onOpenDelete: onOpenDeleteUser }), children: _jsxs(_Fragment, { children: [_jsx(Td, { children: role }), _jsxs(Td, { children: [user.firstName, " ", user.lastName] }), _jsx(Td, { children: user.jobTitle }), _jsx(Td, { children: user.email })] }) }, `${user.id} + ${i}`));
                }) }));
        }
        return _jsx(_Fragment, {});
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { width: '100%', display: 'flex', justifyContent: 'flex-end', children: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.systemRole) === SystemRole.SUPER_ADMINISTRATOR ? (_jsx(Button, { onClick: () => {
                        onOpenAddUser();
                    }, w: '124px', h: '36px', marginBottom: '15px', variant: variants.addNewBtn, leftIcon: _jsx(AddIcon, {}), children: "Add New" })) : (_jsx(Box, { w: '124px', h: '36px', marginBottom: '15px' })) }), _jsx(SystemAdminTable, { tableName: TableType.systemAdmin, tableHeaders: systemAdminTableHeaders, children: renderRows() }), _jsx(AddAdminModal, { isOpen: isOpenAddUser, onClose: () => {
                    onCloseAddUser();
                    onUserUpdate();
                } }), selectedUser && selectedUser.email && isLoading && (_jsx(ResendInvitationModal, { email: selectedUser.email, isOpen: isOpenResendInvitation, onClose: onCloseResendInvitation })), _jsx(InvitationSentModal, { isOpen: isOpenInvitationSent, onClose: onCloseInvitationSent }), selectedUser && (_jsx(DeleteModal, { isOpen: isOpenDeleteUser, onClose: onCloseDeleteUser, deleteItem: () => handleDeleteUser(selectedUser, onEnd), title: deleteUserTitle(selectedUser), description: deleteDescription((selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.firstName) + ' ' + (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.lastName)) })), _jsx(EditAdminModal, { currentUserRole: currentUser === null || currentUser === void 0 ? void 0 : currentUser.systemRole, adminToEdit: selectedUser, isOpen: isOpenEditUser, onClose: () => {
                    onCloseEditUser();
                    setSelectedUser(undefined);
                    onUserUpdate();
                } })] }));
};
