import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Center, Flex, HStack, Image, Text, VStack, } from '@chakra-ui/react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { useReports } from '../../components/context/ReportsContext';
import mySiteIqLogo from '../../images/mySiteIqLogo.gif';
import { ClientConciergeText } from '../../sharedComponents/ClientConciergeText';
import { RefreshReportBtn } from './RefreshReportBtn';
import { ShareReportBtn } from './ShareReportBtn';
export const ReportContent = ({ org, onReportLoaded, reportId, retrievalError, embedConfig, token, canViewReports, canEditReports, }) => {
    const { reports, selectReport, currentReport } = useReports();
    const [isLoaded, setIsLoaded] = useState(false);
    const [noAccess, setNoAccess] = useState(false);
    const [_, setReport] = useState();
    useEffect(() => {
        if (currentReport && !currentReport.isPublished) {
            if (!canEditReports) {
                setNoAccess(true);
            }
        }
    }, [currentReport, canEditReports]);
    useEffect(() => {
        if (reportId) {
            selectReport(parseInt(reportId));
            if (currentReport) {
                onReportLoaded(currentReport);
            }
        }
    }, [reportId, reports, currentReport]);
    const renderNoReports = () => {
        if (!retrievalError && !isLoaded && !noAccess) {
            return _jsx(Image, { width: '50%', src: mySiteIqLogo });
        }
        else {
            return (_jsxs(VStack, { children: [_jsx(Text, { children: "This report is not available." }), _jsx(ClientConciergeText, { concierge: org === null || org === void 0 ? void 0 : org.clientConcierge })] }));
        }
    };
    return (_jsxs(_Fragment, { children: [currentReport && token && !noAccess && (_jsxs(Box, { height: '100%', width: '100%', display: isLoaded ? 'contents' : 'none', children: [_jsxs(HStack, { pt: '3px', justifyContent: 'end', children: [org && canViewReports && !currentReport.isCustom && (_jsx(RefreshReportBtn, { onPowerBILoaded: (loadedValue) => {
                                    setIsLoaded(loadedValue);
                                } })), _jsx(ShareReportBtn, { orgKey: org === null || org === void 0 ? void 0 : org.identity, reportId: currentReport.reportId })] }), _jsx(PowerBIEmbed, { "data-testid": 'powerbi-embed', embedConfig: embedConfig, eventHandlers: new Map([
                            [
                                'loaded',
                                () => {
                                    console.info('Report is Loaded.');
                                    setIsLoaded(true);
                                },
                            ],
                            [
                                'rendered',
                                () => {
                                    console.info('Report is Rendered.');
                                },
                            ],
                            [
                                'error',
                                (e) => {
                                    console.error(e.details);
                                },
                            ],
                        ]), cssClassName: 'report-style-class', getEmbeddedComponent: (embedObject) => {
                            console.info(`Embedded object of type "${embedObject.embedtype}" received`);
                            setReport(embedObject);
                        } })] })), _jsx(Flex, { h: '100%', alignContent: 'center', display: !isLoaded ? 'contents' : 'none', children: _jsx(Center, { h: '100%', children: renderNoReports() }) })] }));
};
