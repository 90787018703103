import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
export const TextInput = ({ label, register, inputType, required, errorMsg, disabled, }) => {
    return (_jsxs(FormControl, { children: [_jsxs(FormLabel, { mr: '3px', children: [label, required && (_jsxs("span", { className: 'required', style: { color: appColors.DELETE_ERROR }, children: [' ', "*"] }))] }), _jsx(Input, Object.assign({ type: inputType ? inputType : 'text', placeholder: label }, register, { isDisabled: disabled })), errorMsg && (_jsx("span", { style: {
                    color: 'red',
                    marginTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: errorMsg }))] }));
};
