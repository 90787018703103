import { createSlice } from '@reduxjs/toolkit';
import { appValues } from '../config/constants';
export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        available: false,
        expanded: false,
        width: appValues.SIDEBAR_WIDTH_SMALL,
        previewActive: false,
        isLoadedPowerBI: false,
        contentAvailable: true,
    },
    reducers: {
        setAvailable: (state, { payload: available }) => {
            state.available = available;
            if (!state.available) {
                state.width = 0;
            }
            else {
                if (state.expanded) {
                    state.width = appValues.SIDEBAR_WIDTH_FULL;
                }
                else {
                    state.width = appValues.SIDEBAR_WIDTH_SMALL;
                }
            }
        },
        setExpanded: (state, { payload: expanded }) => {
            state.expanded = expanded;
            if (!state.available) {
                state.width = 0;
            }
            else {
                if (expanded) {
                    state.width = appValues.SIDEBAR_WIDTH_FULL;
                }
                else {
                    state.width = appValues.SIDEBAR_WIDTH_SMALL;
                }
            }
        },
        setIsLoadedPowerBI: (state, { payload: isLoadedPowerBI }) => {
            state.isLoadedPowerBI = isLoadedPowerBI;
        },
        setPreviewActive: (state, { payload: previewActive }) => {
            state.previewActive = previewActive;
        },
        setContentAvailable: (state, { payload: contentAvailable }) => {
            state.contentAvailable = contentAvailable;
        },
    },
});
export const selectAvailable = (state) => state.sidebar.available;
export const selectExpanded = (state) => state.sidebar.expanded;
export const selectIsLoadedPowerBI = (state) => state.sidebar.isLoadedPowerBI;
export const selectPreviewActive = (state) => state.sidebar.previewActive;
export const selectWidth = (state) => state.sidebar.width;
export const selectContentAvailable = (state) => state.sidebar.contentAvailable;
export const { setAvailable, setExpanded, setIsLoadedPowerBI, setPreviewActive, setContentAvailable, } = sidebarSlice.actions;
export default sidebarSlice.reducer;
