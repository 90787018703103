var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getStates, getVerticalMarkets } from '../services/api/lookupsAPI';
export const fetchVerticalMarkets = createAsyncThunk('verticalMarkets/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    return yield getVerticalMarkets();
}));
export const fetchStates = createAsyncThunk('states/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    return yield getStates();
}));
export const initialState = {
    verticalMarkets: [],
    states: [],
};
export const lookupsSlice = createSlice({
    name: 'lookups',
    initialState,
    reducers: {
        setVerticalMarkets: (state, { payload }) => {
            state.verticalMarkets = payload;
        },
        setStates: (state, { payload }) => {
            state.states = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVerticalMarkets.fulfilled, (state, action) => {
            state.verticalMarkets = action.payload;
        });
        builder.addCase(fetchStates.fulfilled, (state, action) => {
            state.states = action.payload;
        });
    },
});
export const selectVerticalMarkets = (state) => state.lookups.verticalMarkets;
export const selectStates = (state) => state.lookups.states;
export const { setVerticalMarkets, setStates } = lookupsSlice.actions;
export default lookupsSlice.reducer;
