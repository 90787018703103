import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Center, Image, Stack } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { logoSupportedFormats } from '../../../config/constants';
import { EditIcon } from '../../../config/icons';
export const ImageUpload = ({ initialImageUrl, imageRef, handleImageErrorMessage, org, editMode, }) => {
    // the useRef hook does not trigger re-renders, so we set these state
    // variables when the selected file changes
    const [image, setImageState] = useState(undefined);
    const [imageUrl, setImageUrl] = useState(initialImageUrl);
    useEffect(() => {
        if (initialImageUrl) {
            setImageUrl(initialImageUrl);
        }
    }, [initialImageUrl, editMode]);
    useEffect(() => {
        if (image) {
            setImageUrl(URL.createObjectURL(image));
        }
    }, [image]);
    const setImage = () => {
        var _a, _b;
        const input = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current;
        if (input) {
            const file = (_b = (_a = input.files) === null || _a === void 0 ? void 0 : _a.item(0)) !== null && _b !== void 0 ? _b : undefined;
            if (file) {
                setImageState(file);
                setImageUrl(URL.createObjectURL(file));
                handleImageErrorMessage(file, logoSupportedFormats);
            }
        }
    };
    return (_jsxs(Box, { h: '189px', w: 'auto', maxWidth: '311px', borderRadius: '8px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', boxSizing: 'border-box', p: '19px', alignContent: 'center', children: [_jsx(Center, { children: _jsxs(Stack, { children: [_jsx(Box, { h: editMode ? '90px' : '140px', children: imageUrl && (_jsx(Image, { src: imageUrl, w: '100%', h: '100%', fit: 'contain', alt: `${org === null || org === void 0 ? void 0 : org.name} logo` })) }), editMode && (_jsx(Center, { children: _jsx(Button, { onClick: () => {
                                    var _a;
                                    (_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.click();
                                }, variant: variants.editBtn, leftIcon: _jsx(EditIcon, {}), "aria-label": 'edit logo', children: "Edit" }) }))] }) }), _jsx("input", { type: 'file', style: { display: 'none' }, accept: 'image/*', ref: imageRef, onChange: setImage })] }));
};
