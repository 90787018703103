import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import React from 'react';
export function EscalationFactorNumberInput({ canEdit, data, onChangeInput, min, max, }) {
    return (_jsxs(NumberInput, { "aria-label": 'escalation-factor-number-input', value: data, min: min, max: max, w: '106px', isDisabled: !canEdit, onChange: (valueString) => {
            onChangeInput(valueString);
        }, variant: variants.msiqBaseStyle, children: [_jsx(NumberInputField, { textAlign: 'end', fontWeight: 600 }), _jsxs(NumberInputStepper, { left: '10px', children: [_jsx(NumberIncrementStepper, { border: 'none', "aria-label": 'escalation-factor-increment-button', children: _jsx(ChevronUpIcon, { boxSize: '18px' }) }), _jsx(NumberDecrementStepper, Object.assign({ "aria-label": 'escalation-factor-decrement-button', border: 'none' }, (data === 'None'
                        ? {
                            opacity: 0.4,
                            cursor: 'not-allowed',
                        }
                        : {}), { children: _jsx(ChevronDownIcon, { boxSize: '18px' }) }))] })] }));
}
