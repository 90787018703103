import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LinkIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Input, InputGroup, InputRightElement, Text, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { InfoWarningModal } from '../../sharedComponents/InfoWarningModal';
export const ShareReportBtn = ({ orgKey, reportId, }) => {
    const shareReportUrl = orgKey
        ? `${window.location.origin}/${orgKey}/${reportId}`
        : 'Unavailable';
    const { isOpen: isOpenShareReport, onOpen: onOpenShareReport, onClose: onCloseShareReport, } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(Box, {}), _jsx(Button, { onClick: onOpenShareReport, variant: variants.transparentReportBtn, leftIcon: _jsx(LinkIcon, {}), children: "Share Report" }), _jsx(InfoWarningModal, { isOpen: isOpenShareReport, onClose: onCloseShareReport, children: _jsxs(_Fragment, { children: [_jsx(Text, { children: "The URL to share this report is:" }), _jsxs(InputGroup, { children: [_jsx(Input, { value: shareReportUrl, contentEditable: false, pr: '4rem' }), _jsx(InputRightElement, { width: '3.75rem', children: _jsx(Button, { onClick: () => {
                                            navigator.clipboard.writeText(shareReportUrl);
                                        }, variant: variants.orangeBtn, h: '1.75rem', mr: '.5rem', children: "COPY" }) })] }), _jsx(HStack, { justifyContent: 'space-between', children: _jsx(Button, { variant: variants.orangeBtn, w: '100%', onClick: () => {
                                    navigator.clipboard.writeText(shareReportUrl);
                                    onCloseShareReport();
                                }, children: "Close" }) })] }) })] }));
};
