import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ChakraProvider } from '@chakra-ui/react';
import theme from '@frontend/design-system/theme/theme';
import { DependenciesProvider } from '@frontend/domain/contexts/Dependencies/DependenciesProvider';
import { FeatureFlagProvider } from '@frontend/domain/contexts/FeatureFlags/FeatureFlagProvider';
import { ToastContainer } from '@frontend/domain/ToastContainer';
import { Authorization } from '@frontend/shared/src/components/Authentication/Authorization';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { authConfig } from './authConfig';
import { environment } from './config/environment';
import './index.css';
import { store } from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
const { domain, clientId, authorizationParams: { redirectUri, audience }, } = authConfig;
export const authOptions = {
    domain,
    clientId,
    authorizationParams: {
        redirect_uri: redirectUri,
        audience: audience,
        scope: 'email read:current_user update:current_user_metadata',
    },
};
root.render(_jsx(React.StrictMode, { children: _jsx(Provider, { store: store, children: _jsx(ChakraProvider, { theme: theme, children: _jsx(BrowserRouter, { children: _jsx(Authorization, { authOptions: authOptions, children: _jsx(DependenciesProvider, { mysiteApi: {
                            baseUrl: environment.apis.mysiteIQAPI,
                            token: '',
                        }, platformApi: {
                            baseUrl: environment.apis.platformAPI,
                            token: '',
                        }, children: _jsx(FeatureFlagProvider, { children: _jsxs(_Fragment, { children: [_jsx(App, {}), _jsx(ToastContainer, {})] }) }) }) }) }) }) }) }));
