import { jsx as _jsx } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import React from 'react';
export const OnHoverWrapper = ({ children, canEdit, setHoverMode, customStyles, }) => {
    return (_jsx(HStack, Object.assign({}, (canEdit
        ? {
            _hover: {
                bg: '#FEF3EA',
                pl: '5px',
            },
        }
        : {}), { w: '100%', h: '40px', style: customStyles, onMouseEnter: () => {
            setHoverMode(true);
        }, onMouseLeave: () => {
            setHoverMode(false);
        }, children: children })));
};
