import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useOrganizationList } from '../../hooks/Organization/OrganizationListHook';
import { organizationSessionStore } from './OrganizationSessionHandler';
import { OrganizationSummaryContext } from './OrganizationSummaryContext';
export function OrganizationSummaryProvider({ children, context, }) {
    const [selectedOrganizationIdentity, setSelectedOrganizationIdentity] = useState();
    const { organizations, isLoading, onUpdateOrganization } = useOrganizationList({
        context,
    });
    const { setSessionOrgIdentity } = organizationSessionStore();
    const selectOrganizationHandler = (identity) => {
        setSessionOrgIdentity(identity);
        setSelectedOrganizationIdentity(identity);
    };
    return (_jsx(OrganizationSummaryContext.Provider, { value: {
            selectedOrganizationIdentity,
            organizations,
            onSelectOrganization: selectOrganizationHandler,
            isLoading,
            onUpdateOrganization,
        }, children: children }));
}
