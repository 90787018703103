import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { SystemRoleEnum } from '../../objects/UserData';
export const SystemRoleInput = ({ label, defaultValue, children, required, errorMsg, setRoleId, disabled, }) => {
    return (_jsxs(FormControl, { children: [_jsxs(FormLabel, { children: [label, required && (_jsxs("span", { className: 'required', style: { color: appColors.DELETE_ERROR }, children: [' ', "*"] }))] }), _jsx(Select, { onChange: (e) => {
                    if (e.target.selectedOptions[0].value ===
                        SystemRoleEnum.ORG_APPROVER) {
                        setRoleId(SystemRoleEnum.ORG_APPROVER);
                    }
                    else if (e.target.selectedOptions[0].value ===
                        SystemRoleEnum.SUPER_ADMIN) {
                        setRoleId(SystemRoleEnum.SUPER_ADMIN);
                    }
                    else {
                        setRoleId(SystemRoleEnum.USER);
                    }
                }, defaultValue: defaultValue, placeholder: 'Select', isDisabled: disabled, "data-testid": 'select', children: children }), errorMsg && (_jsx("span", { style: {
                    color: 'red',
                    marginTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: errorMsg }))] }));
};
