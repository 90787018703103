import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Image, Input, Radio, RadioGroup, Spacer, Switch, Text, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { IAppNames } from '@frontend/domain/models/App';
import { SystemRole } from '@frontend/domain/models/Roles';
import { useState } from 'react';
import { appColors } from '../../../config/constants';
import { getAppNameAndLogo } from '../../../config/helperFunctions';
import { ModalType, } from '../../../objects/UserData';
import { AddAdminOrConciergeModal } from '../ConciergeAndAdminModals/AddConciergeOrAdminModal';
export const UsersAccess = ({ canEditAppContext, editMode, newPrimaryContactIdIdentity, org, orgAdmins, renderText, requiredFields, setAddAdminOrConcierge, setNewPrimaryContactIdIdentity, setClientConciergeOnNewOrg, localAdminsOnNewOrg, setLocalAdminsOnNewOrg, primaryContactOnNewOrgEmail, setPrimaryContactOnNewOrgEmail, currentUser, canAddLocalAdmin, onSelectOrganization, upsertOrganization, orgApps, handleOrgEnable, handleOrgExpires, }) => {
    const [modalType, setModalType] = useState(ModalType.admin);
    const { isOpen: isOpenAddConcierge, onOpen: onOpenAddConcierge, onClose: onCloseAddConcierge, } = useDisclosure();
    const { isOpen: isOpenAddAdmin, onOpen: onOpenAddAdmin, onClose: onCloseAddAdmin, } = useDisclosure();
    const renderClientConcierge = (label) => {
        return (_jsx(FormControl, { isRequired: editMode && requiredFields.includes(label), children: _jsxs(HStack, { display: 'flex', justifyContent: 'space-between', children: [renderText(label), editMode &&
                        currentUser &&
                        currentUser.systemRole !== SystemRole.USER && (_jsx(Button, { variant: variants.editBtn, onClick: () => {
                            setAddAdminOrConcierge(true);
                            setModalType(ModalType.concierge);
                            onOpenAddConcierge();
                        }, children: "Replace" })), _jsx(AddAdminOrConciergeModal, { org: org, modalType: modalType, setAddAdminOrConcierge: setAddAdminOrConcierge, isOpen: isOpenAddConcierge, onClose: onCloseAddConcierge, setClientConciergeOnNewOrg: setClientConciergeOnNewOrg, localAdminsOnNewOrg: localAdminsOnNewOrg, setLocalAdminsOnNewOrg: setLocalAdminsOnNewOrg, onSelectOrganization: onSelectOrganization, upsertOrganization: upsertOrganization })] }) }));
    };
    const renderLocalAdmin = (label) => {
        return (_jsxs(FormControl, { isRequired: editMode && requiredFields.includes(label), children: [_jsxs(HStack, { display: 'flex', justifyContent: 'space-between', children: [_jsxs(Box, { children: [_jsx(FormLabel, { color: appColors.PRIM_BLUE, mb: '0', children: label }), _jsx(Text, { fontSize: '10px', paddingLeft: '18px', children: "Select one primary contact" })] }), editMode && canAddLocalAdmin && (_jsxs(_Fragment, { children: [_jsx(Button, { variant: variants.editBtn, onClick: () => {
                                        setAddAdminOrConcierge(true);
                                        setModalType(ModalType.admin);
                                        onOpenAddAdmin();
                                    }, children: "Add new" }), _jsx(AddAdminOrConciergeModal, { org: org, modalType: modalType, setAddAdminOrConcierge: setAddAdminOrConcierge, isOpen: isOpenAddAdmin, onClose: onCloseAddAdmin, setClientConciergeOnNewOrg: setClientConciergeOnNewOrg, localAdminsOnNewOrg: localAdminsOnNewOrg, setLocalAdminsOnNewOrg: setLocalAdminsOnNewOrg, onSelectOrganization: onSelectOrganization, upsertOrganization: upsertOrganization })] }))] }), localAdminsOnNewOrg.length > 0 && (_jsx(RadioGroup, { onChange: (e) => setPrimaryContactOnNewOrgEmail(e), value: primaryContactOnNewOrgEmail, isDisabled: !editMode, children: localAdminsOnNewOrg.map((admin, i) => {
                        return (_jsx(HStack, { ml: '15px', children: _jsx(Radio, { value: admin.emailAddress, colorScheme: 'orange', children: _jsxs(Text, { children: [admin.firstName, " ", admin.lastName] }) }) }, `${admin.emailAddress} ${i}`));
                    }) })), localAdminsOnNewOrg.length === 0 && (_jsx(RadioGroup, { onChange: (e) => setNewPrimaryContactIdIdentity(e), value: newPrimaryContactIdIdentity, isDisabled: !editMode, children: orgAdmins &&
                        orgAdmins.map((admin, i) => {
                            var _a, _b;
                            return (_jsx(HStack, { ml: '15px', children: _jsx(Radio, { value: `${(_a = admin.id) === null || _a === void 0 ? void 0 : _a.toUpperCase()},${admin.userIdentity}`, colorScheme: 'orange', children: _jsxs(Text, { children: [admin.firstName, ' ', admin.lastName] }) }) }, `${(_b = admin.id) === null || _b === void 0 ? void 0 : _b.toUpperCase()} ${i}`));
                        }) }))] }));
    };
    const renderAppCheckboxRow = (app, key) => {
        const { icon, name } = getAppNameAndLogo(app);
        const currentApp = orgApps.find((orgApp) => {
            return orgApp.app === app;
        });
        return (_jsxs(Flex, { align: 'center', mt: '10px', flexWrap: 'wrap', children: [_jsxs(Flex, { align: 'center', children: [_jsx(Box, { children: _jsx(Image, { src: icon, mr: '5px', boxSize: '30px' }) }), _jsx(Heading, { as: 'h5', size: 'xs', w: '100px', children: name })] }), _jsx(Spacer, {}), _jsxs(Flex, { align: 'center', children: [_jsx(Text, { fontSize: 'sm', mr: '5px', children: "Access\u00A0Available\u00A0Until" }), _jsx(Input, { type: 'date', mr: '5px', size: 'xs', isDisabled: !editMode, value: (currentApp === null || currentApp === void 0 ? void 0 : currentApp.expiresOn)
                                ? currentApp.expiresOn
                                    .toISOString()
                                    .slice(0, 10)
                                : '', onChange: (e) => handleOrgExpires(app, e.target.value) }), _jsx(Switch, { colorScheme: 'orange', size: 'sm', isDisabled: !editMode, isChecked: currentApp === null || currentApp === void 0 ? void 0 : currentApp.enabled, onChange: (e) => handleOrgEnable(app, e.target.checked), "aria-label": key })] })] }));
    };
    const renderApps = () => {
        return (_jsxs(Box, { mt: '20px !important', children: [_jsx(Heading, { size: 'md', children: "Access" }), _jsxs(FormControl, { children: [renderAppCheckboxRow(IAppNames.REPORTS, REPORTS_KEY), renderAppCheckboxRow(IAppNames.FCA, FCA_KEY), renderAppCheckboxRow(IAppNames.PLANNER, PLANNER_KEY), renderAppCheckboxRow(IAppNames.PIQ, PIQ_KEY)] })] }));
    };
    return (_jsxs(_Fragment, { children: [renderClientConcierge('Client concierge'), renderLocalAdmin('Local Admin'), canEditAppContext && renderApps()] }));
};
const REPORTS_KEY = 'reports';
const PLANNER_KEY = 'planner';
const FCA_KEY = 'fca';
const PIQ_KEY = 'piq';
