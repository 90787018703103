import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Text, VStack } from '@chakra-ui/react';
import { consolidateName } from '@frontend/domain/conversion/NameConversions';
export function UnauthorizedOrg({ organization }) {
    var _a, _b, _c;
    return (_jsx(Center, { h: '85vh', w: '100%', children: _jsxs(VStack, { gap: '2', children: [_jsx(Text, { children: "At this moment, you do not have access for this organization." }), _jsx(Text, { children: (organization === null || organization === void 0 ? void 0 : organization.clientConcierge)
                        ? `Please contact your client concierge, ${consolidateName({
                            firstName: (_a = organization.clientConcierge) === null || _a === void 0 ? void 0 : _a.firstName,
                            lastName: (_b = organization.clientConcierge) === null || _b === void 0 ? void 0 : _b.lastName,
                        })} at ${(_c = organization.clientConcierge) === null || _c === void 0 ? void 0 : _c.email}`
                        : 'Please contact your client concierge' })] }) }));
}
export function UnauthorizedMessage() {
    return (_jsx(Center, { h: '85vh', w: '100%', children: _jsx(VStack, { gap: '2', children: _jsx(Text, { children: "At this moment, you do not have the proper permission to see this page." }) }) }));
}
