import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
export const SelectInputNoRegister = ({ label, defaultValue, value, children, required, disabled, onChange, id, errorMsg, }) => {
    return (_jsxs(FormControl, { children: [label && (_jsxs(FormLabel, { children: [label, required && (_jsxs("span", { className: 'required', style: { color: appColors.DELETE_ERROR }, children: [' ', "*"] }))] })), _jsx(Select, { isDisabled: disabled, defaultValue: defaultValue, value: value, placeholder: 'Select', onChange: onChange, id: id, "data-testid": id, bg: 'white', required: value ? false : true, children: children }), ' ', errorMsg && (_jsx("span", { style: {
                    color: 'red',
                    marginTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: errorMsg }))] }));
};
