var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import { useState } from 'react';
import { getTagsForOrg } from '../../../services/api/orgDetailsAPI';
import { MyPhotosBody } from './MyPhotosBody';
import { MyPhotosHeader } from './MyPhotosHeader';
export const tagColors = [
    '#C00000',
    '#FF0000',
    '#FFC000',
    '#FFF000',
    '#92D050',
    '#00B050',
    '#00B0F0',
    '#0070C0',
    '#002060',
    '#7030A0',
];
export const MyPhotosTab = ({ org, canEdit }) => {
    const [orgTags, setOrgTags] = useState([]);
    const getTags = () => __awaiter(void 0, void 0, void 0, function* () {
        if (org === null || org === void 0 ? void 0 : org.identity) {
            const tags = yield getTagsForOrg(org.identity);
            setOrgTags(tags);
        }
    });
    return (_jsx(_Fragment, { children: _jsxs(HStack, { w: '40%', display: 'grid', children: [_jsx(MyPhotosHeader, { canEdit: canEdit, orgIdentity: org === null || org === void 0 ? void 0 : org.identity, orgTags: orgTags, getTags: getTags }), _jsx(MyPhotosBody, { canEdit: canEdit, orgIdentity: org === null || org === void 0 ? void 0 : org.identity, orgTags: orgTags, getTags: getTags })] }) }));
};
