var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, useToast } from '@chakra-ui/react';
import { ArrowRight } from '../../../config/icons';
import { usePlatformUserApi } from '../../../services/api/platformUserApiHook';
const ActionsBodyUserModal = ({ user, canDelete, onOpenAddUser, canResendInvitation, setIsLoading, onOpenInvitationSent, onCloseInvitationSent, onCloseResendInvitation, setUser, onOpenDelete, }) => {
    const { resendInvite } = usePlatformUserApi();
    const toast = useToast();
    const resendInvitation = (user) => __awaiter(void 0, void 0, void 0, function* () {
        const handleInvitationSentModal = () => {
            onOpenInvitationSent();
            setTimeout(onCloseInvitationSent, 1500);
        };
        try {
            setIsLoading(true);
            if (user && user.userIdentity) {
                yield resendInvite({ userId: user.userIdentity });
            }
            setIsLoading(false);
            handleInvitationSentModal();
            setUser(undefined);
        }
        catch (e) {
            console.log(e);
            onCloseResendInvitation();
            onCloseInvitationSent();
            toast({
                title: 'Resend invitation failed',
                status: 'error',
                isClosable: true,
            });
            setUser(undefined);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(ArrowRight, { position: 'absolute', right: '3' }), _jsxs(Box, { maxW: 'm', mt: '5px', fontSize: '12px', children: [_jsx(Box, { onClick: () => {
                            setUser(user);
                            onOpenAddUser();
                        }, cursor: 'pointer', mb: '12px', children: "Edit" }), canResendInvitation && (_jsx(Box, { onClick: () => {
                            setUser(user);
                            resendInvitation(user);
                        }, cursor: 'pointer', mb: '12px', "data-testid": 'resend-invite', children: "Resend invitation" })), canDelete && (_jsx(Box, { onClick: () => {
                            setUser(user);
                            onOpenDelete();
                        }, cursor: 'pointer', children: "Delete" }))] })] }));
};
export default ActionsBodyUserModal;
