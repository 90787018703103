var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class RestService {
    constructor(http, baseUri) {
        this.http = http;
        this.baseUri = baseUri;
    }
    setTokenRetrievalFunction(getTokenFunction) {
        this.getToken = getTokenFunction;
    }
    get(urlPath, headers = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.createURL(urlPath);
            Object.assign(headers, yield this.getHeaders());
            const response = yield this.http.get(url, { headers });
            return response.data;
        });
    }
    getWithQueryParams(urlPath, headers = {}, params = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.createURL(urlPath);
            Object.assign(headers, yield this.getHeaders());
            const response = yield this.http.get(url, { headers, params });
            return response.data;
        });
    }
    post(path, body, headers = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.createURL(path);
            Object.assign(headers, yield this.getHeaders());
            const response = yield this.http.post(url, body, { headers });
            return response.data;
        });
    }
    postFormData(path, body, headers = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.createURL(path);
            Object.assign(headers, yield this.getHeaders());
            headers['Content-Type'] = 'multipart/form-data';
            const response = yield this.http.post(url, body, { headers });
            return response.data;
        });
    }
    put(path, body, headers = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.createURL(path);
            Object.assign(headers, yield this.getHeaders());
            const response = yield this.http.put(url, body, { headers });
            return response.data;
        });
    }
    delete(path, headers = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.createURL(path);
            Object.assign(headers, yield this.getHeaders());
            const response = yield this.http.delete(url, { headers });
            return response.data;
        });
    }
    createURL(urlPath) {
        return this.baseUri + urlPath;
    }
    getHeaders() {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = {};
            if (this.getToken) {
                const token = yield this.getToken();
                headers['Authorization'] = `Bearer ${token}`;
            }
            return headers;
        });
    }
}
