import { jsx as _jsx } from "react/jsx-runtime";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { AppLoading } from '../Loading/AppLoading';
import { RequireDependenciesReady } from './RequireDependenciesReady';
export const AuthenticatedPage = withAuthenticationRequired(({ accessTokenContext, children }) => {
    return (_jsx(RequireDependenciesReady, { accessTokenContext: accessTokenContext, children: children }));
}, {
    onRedirecting: () => _jsx(AppLoading, {}),
});
