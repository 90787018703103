var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformOrgApi } from '@/services/api/platformOrgApiHook';
import { createAsyncThunk, createSelector, createSlice, } from '@reduxjs/toolkit';
const initialOrganization = undefined;
export const fetchPlatformSitesByOrgId = createAsyncThunk('organizations/fetchById', (id) => __awaiter(void 0, void 0, void 0, function* () {
    const { getPlatformSitesByOrgId } = usePlatformOrgApi();
    return yield getPlatformSitesByOrgId(id);
}));
export const initialState = {
    authOrg: null,
    organization: initialOrganization,
    platformSites: undefined,
    allOrgs: [],
    userOrgs: [],
    loadingSpinner: false,
    orgLoading: false,
    platformSitesLoading: false,
};
export const orgSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPlatformSitesByOrgId.fulfilled, (state, action) => {
            state.platformSites = action.payload;
            state.platformSitesLoading = false;
        });
        builder.addCase(fetchPlatformSitesByOrgId.pending, (state) => {
            state.platformSitesLoading = true;
        });
        builder.addCase(fetchPlatformSitesByOrgId.rejected, (state) => {
            state.platformSitesLoading = false;
        });
    },
});
export const selectPlatformSites = (state) => {
    return {
        sites: state.organization.platformSites,
        loading: state.organization.platformSitesLoading,
    };
};
export const memoizedSelectPlatformSites = createSelector([selectPlatformSites], (sites) => sites);
export default orgSlice.reducer;
