var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, FormControl, FormLabel, HStack, Stack, Text, useToast, } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { variants } from '@frontend/design-system/theme/theme';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorLabel } from '../../../components/forms/ErrorLabel';
import { FormDropdown } from '../../../components/forms/FormDropdown';
import { appColors, logoSupportedFormats } from '../../../config/constants';
import { BusinessUnitOptions, OrganizationRoleEnum, SystemRoleEnum, VerticalMarketOptions, } from '../../../objects/UserData';
import { usePlatformUserApi } from '../../../services/api/platformUserApiHook';
import { getUserByEmail } from '../../../services/api/profilePageAPI';
import { uploadFileToBlob } from '../../../services/imageUpload';
import { useAppSelector } from '../../../store/hooks';
import { selectStates } from '../../../store/lookupsSlice';
import { SaveNew } from '../../Profile/SaveNew';
import { InputWithPopOver } from '../Facilities/components/InputWithPopOver';
import { FormWithLabel } from './components/FormWithLabel';
import { LogoInput } from './LogoInput';
import { UsersAccess } from './UsersAccess';
export const GeneralInput = ({ orgUsers, canEdit, canEditAppContext, currentUser, canAddLocalAdmin, org, onSelectOrganization, isCreateMode = false, upsertOrganization, }) => {
    var _a;
    const navigate = useNavigate();
    const states = useAppSelector(selectStates);
    const [editing, setEditing] = useState(false);
    const editMode = (editing || isCreateMode) && canEdit;
    const [addAdminOrConcierge, setAddAdminOrConcierge] = useState(false);
    const [newPrimaryContactIdIdentity, setNewPrimaryContactIdIdentity] = useState();
    const [orgAdmins, setOrgAdmins] = useState([]);
    const [clientConciergeOnNewOrg, setClientConciergeOnNewOrg] = useState(undefined);
    const [localAdminsOnNewOrg, setLocalAdminsOnNewOrg] = useState([]);
    const [primaryContactOnNewOrgEmail, setPrimaryContactOnNewOrgEmail] = useState(undefined);
    const [imageError, setImageError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const VALID_EXTENSIONS_ERROR_MESSAGE = `Allowed logo types: ${logoSupportedFormats.join(', ')}.`;
    const validFileSize = 3 * 1024 * 1024; // 3MB
    const VALID_SIZE_ERROR_MESSAGE = `Max logo size: ${validFileSize / 1024 / 1024} MB.`;
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm();
    const { sendInvite, configureUser } = usePlatformUserApi();
    const [orgApps, setOrgApps] = useState([]);
    const toast = useToast();
    const logoSmallRef = useRef(null);
    useEffect(() => {
        if (org) {
            setOrgApps(org.apps);
        }
    }, [org]);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        const apps = orgApps.map((orgApp) => {
            return {
                app: orgApp.app,
                remove: !orgApp.enabled,
                expiresOn: orgApp.expiresOn,
            };
        });
        if (!addAdminOrConcierge) {
            const logoFile = (_c = (_b = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _b === void 0 ? void 0 : _b.files) === null || _c === void 0 ? void 0 : _c.item(0);
            if (logoFile) {
                data.logoSmallName = logoFile.name;
                // TODO: need to handle so blobs aren't uploaded twice
                uploadFileToBlob(logoFile);
            }
            else if (org) {
                //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                data.logoSmallName = getImageNameFromUrl(org.smallLogoUrl);
            }
            let blob = undefined;
            if (((_d = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _d === void 0 ? void 0 : _d.files) &&
                ((_f = (_e = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _e === void 0 ? void 0 : _e.files) === null || _f === void 0 ? void 0 : _f.length) > 0) {
                blob = new Blob([logoSmallRef.current.files[0]], {
                    type: logoSmallRef.current.files[0].type,
                });
            }
            if (org) {
                try {
                    yield upsertOrganization(data, blob, apps, org.primaryContact && {
                        identity: (_g = org.primaryContact) === null || _g === void 0 ? void 0 : _g.identity,
                        firstName: (_h = org.primaryContact) === null || _h === void 0 ? void 0 : _h.firstName,
                        lastName: (_j = org.primaryContact) === null || _j === void 0 ? void 0 : _j.lastName,
                        email: (_k = org.primaryContact) === null || _k === void 0 ? void 0 : _k.email,
                        phoneNumber: (_l = org.primaryContact) === null || _l === void 0 ? void 0 : _l.phoneNumber,
                        jobTitle: (_m = org.primaryContact) === null || _m === void 0 ? void 0 : _m.jobTitle,
                    }, org.clientConcierge && {
                        identity: (_o = org.clientConcierge) === null || _o === void 0 ? void 0 : _o.identity,
                        firstName: (_p = org.clientConcierge) === null || _p === void 0 ? void 0 : _p.firstName,
                        lastName: (_q = org.clientConcierge) === null || _q === void 0 ? void 0 : _q.lastName,
                        email: (_r = org.clientConcierge) === null || _r === void 0 ? void 0 : _r.email,
                        phoneNumber: (_s = org.clientConcierge) === null || _s === void 0 ? void 0 : _s.phoneNumber,
                        jobTitle: (_t = org.clientConcierge) === null || _t === void 0 ? void 0 : _t.jobTitle,
                    }, org.identity);
                    toast({
                        title: 'Saved',
                        status: 'success',
                        isClosable: true,
                    });
                    onSelectOrganization(org.identity);
                    setEditing(false);
                }
                catch (error) {
                    if (org.primaryContact === null) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select primary contact.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    else {
                        if (error instanceof AxiosError) {
                            toast({
                                title: 'Save failed',
                                description: (_u = error.response) === null || _u === void 0 ? void 0 : _u.data,
                                status: 'error',
                                duration: 10000,
                                isClosable: true,
                            });
                        }
                        else {
                            toast({
                                title: 'Save failed',
                                description: 'Please check that all fields are entered correctly.',
                                status: 'error',
                                isClosable: true,
                            });
                        }
                    }
                }
            }
            else {
                try {
                    let primaryContact;
                    let clientConcierge;
                    if (!clientConciergeOnNewOrg) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select a Client Concierge.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    else if (localAdminsOnNewOrg.length === 0) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select a Local Admin.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    else if (!primaryContactOnNewOrgEmail) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select a Primary Contact.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    if (clientConciergeOnNewOrg &&
                        localAdminsOnNewOrg.length > 0 &&
                        primaryContactOnNewOrgEmail) {
                        setIsLoading(true);
                        const createdOrg = yield upsertOrganization(data, blob, apps);
                        onSelectOrganization(createdOrg.identity);
                        navigate(AppRoutes.organizationSettings(createdOrg.identity));
                        if (clientConciergeOnNewOrg.isNew) {
                            yield sendInvite({
                                firstName: clientConciergeOnNewOrg.firstName,
                                lastName: clientConciergeOnNewOrg.lastName,
                                jobTitle: clientConciergeOnNewOrg.jobTitle,
                                phoneNumber: clientConciergeOnNewOrg.phoneNumber,
                                emailAddress: clientConciergeOnNewOrg.emailAddress,
                                systemRole: SystemRoleEnum.USER,
                                organizationRoles: [
                                    {
                                        organizationId: createdOrg.id,
                                        organizationIdentity: createdOrg.identity,
                                        role: OrganizationRoleEnum.CLIENT_CONCIERGE,
                                        unassign: false,
                                    },
                                ],
                            });
                            const newUser = yield getUserByEmail(
                            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            clientConciergeOnNewOrg.emailAddress);
                            clientConcierge = newUser;
                        }
                        else {
                            yield configureUser({
                                userId: clientConciergeOnNewOrg.userId,
                                systemRole: clientConciergeOnNewOrg.systemRole,
                                firstName: clientConciergeOnNewOrg.firstName,
                                lastName: clientConciergeOnNewOrg.lastName,
                                phoneNumber: clientConciergeOnNewOrg.phoneNumber,
                                //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                jobTitle: clientConciergeOnNewOrg.jobTitle,
                                organizationRoles: [
                                    {
                                        organizationId: createdOrg.id,
                                        organizationIdentity: createdOrg.identity,
                                        role: OrganizationRoleEnum.CLIENT_CONCIERGE,
                                        unassign: false,
                                    },
                                ],
                            });
                            const newUser = yield getUserByEmail(
                            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            clientConciergeOnNewOrg.emailAddress);
                            clientConcierge = newUser;
                        }
                        for (let i = 0; i < localAdminsOnNewOrg.length; i++) {
                            if (localAdminsOnNewOrg[i].isNew) {
                                yield sendInvite({
                                    firstName: localAdminsOnNewOrg[i].firstName,
                                    lastName: localAdminsOnNewOrg[i].lastName,
                                    jobTitle: localAdminsOnNewOrg[i].jobTitle,
                                    phoneNumber: localAdminsOnNewOrg[i].phoneNumber,
                                    emailAddress: localAdminsOnNewOrg[i].emailAddress,
                                    systemRole: SystemRoleEnum.USER,
                                    organizationRoles: [
                                        {
                                            organizationId: createdOrg.id,
                                            organizationIdentity: createdOrg.identity,
                                            role: OrganizationRoleEnum.LOCAL_ADMIN,
                                            unassign: false,
                                        },
                                    ],
                                });
                                if (localAdminsOnNewOrg[i].emailAddress ===
                                    primaryContactOnNewOrgEmail) {
                                    const newUser = yield getUserByEmail(
                                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    localAdminsOnNewOrg[i].emailAddress);
                                    primaryContact = newUser;
                                }
                            }
                            else {
                                yield configureUser({
                                    userId: localAdminsOnNewOrg[i].userId,
                                    systemRole: localAdminsOnNewOrg[i].systemRole,
                                    firstName: localAdminsOnNewOrg[i].firstName,
                                    lastName: localAdminsOnNewOrg[i].lastName,
                                    phoneNumber: localAdminsOnNewOrg[i].phoneNumber,
                                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    jobTitle: localAdminsOnNewOrg[i].jobTitle,
                                    organizationRoles: [
                                        {
                                            organizationId: createdOrg.id,
                                            organizationIdentity: createdOrg.identity,
                                            role: OrganizationRoleEnum.LOCAL_ADMIN,
                                            unassign: false,
                                        },
                                    ],
                                });
                                if (localAdminsOnNewOrg[i].emailAddress ===
                                    primaryContactOnNewOrgEmail) {
                                    const newUser = yield getUserByEmail(
                                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    localAdminsOnNewOrg[i].emailAddress);
                                    primaryContact = newUser;
                                }
                            }
                        }
                        yield upsertOrganization(data, blob, apps, primaryContact && {
                            identity: primaryContact.userIdentity,
                            firstName: primaryContact.firstName,
                            lastName: primaryContact.lastName,
                            email: primaryContact.email,
                            phoneNumber: primaryContact.phoneNumber,
                            jobTitle: primaryContact.jobTitle,
                        }, clientConcierge && {
                            identity: clientConcierge.userIdentity,
                            firstName: clientConcierge.firstName,
                            lastName: clientConcierge.lastName,
                            email: clientConcierge.email,
                            phoneNumber: clientConcierge.phoneNumber,
                            jobTitle: clientConcierge.jobTitle,
                        }, createdOrg.identity);
                        toast({
                            title: 'Saved',
                            status: 'success',
                            isClosable: true,
                        });
                        setClientConciergeOnNewOrg(undefined);
                        setLocalAdminsOnNewOrg([]);
                        setPrimaryContactOnNewOrgEmail(undefined);
                    }
                }
                catch (error) {
                    if (error instanceof AxiosError) {
                        toast({
                            title: 'Save failed',
                            description: (_v = error.response) === null || _v === void 0 ? void 0 : _v.data,
                            status: 'error',
                            duration: 10000,
                            isClosable: true,
                        });
                    }
                    else {
                        toast({
                            title: 'Save failed',
                            description: 'Please check that all fields are entered correctly.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                }
                finally {
                    setIsLoading(false);
                }
            }
        }
    });
    const getImageNameFromUrl = (url) => {
        if (!url) {
            return '';
        }
        // TODO: make env specific and consolidate buckets
        const urlArray = url.split('https://mysiteiqstorage.blob.core.windows.net/dev-images/');
        return urlArray[1];
    };
    const nameOfOrgRegister = register(NAME_OF_ORG_KEY, { required: true });
    const businessUnitRegister = register(BUSINESS_UNIT_KEY, {
        required: true,
    });
    const verticalMarketRegister = register(VERTICAL_MARKET_KEY, {
        required: true,
    });
    const streetAddressRegister = register(STREET_ADDRESS_KEY, {
        required: true,
    });
    const cityRegister = register(CITY_KEY, { required: true });
    const stateRegister = register(STATE_KEY, { required: true });
    const zipCodeRegister = register(ZIP_CODE_KEY, { required: true });
    const companyWebsiteRegister = register(COMPANY_WEBSITE_KEY, {
        required: true,
    });
    useEffect(() => {
        if (isCreateMode) {
            setEditing(true);
            setClientConciergeOnNewOrg(undefined);
            setLocalAdminsOnNewOrg([]);
            setPrimaryContactOnNewOrgEmail(undefined);
        }
    }, []);
    useEffect(() => {
        if (orgUsers && org) {
            let localAdmins = [];
            orgUsers.forEach((user) => {
                var _a;
                const orgRole = (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => orgRole.organizationIdentity === org.identity);
                if ((orgRole &&
                    orgRole.role === OrganizationRoleEnum.LOCAL_ADMIN) ||
                    OrganizationRoleEnum.CLIENT_CONCIERGE) {
                    localAdmins = [...localAdmins, user];
                }
            });
            setOrgAdmins(localAdmins);
        }
    }, [orgUsers, org]);
    useEffect(() => {
        var _a;
        if (org) {
            setValue(NAME_OF_ORG_KEY, org.name);
            setValue(BUSINESS_UNIT_KEY, org.businessUnit);
            setValue(VERTICAL_MARKET_KEY, org.verticalMarket);
            setValue(COMPANY_WEBSITE_KEY, org.companyWebsite);
            setValue(STREET_ADDRESS_KEY, org.address1);
            setValue(CITY_KEY, org.city);
            setValue(STATE_KEY, org.stateOrProvince);
            setValue(ZIP_CODE_KEY, org.postalCode);
            setNewPrimaryContactIdIdentity(`${(_a = org.primaryContact) === null || _a === void 0 ? void 0 : _a.identity}`);
        }
    }, [setValue, org]);
    const requiredFields = [
        'Name of the organization',
        'Business Unit',
        'Company website',
        'Vertical Market',
        'City',
        'State',
        'Street Address',
        'Zip Code',
    ];
    const renderError = (register, label) => {
        if (errors[register.name] ||
            (register.name.includes('address') &&
                errors.address &&
                errors.address[register.name.split('.')[1]])) {
            return _jsx(ErrorLabel, { label: label });
        }
        else {
            return _jsx("p", { style: { visibility: 'hidden' }, children: "Valid input format" });
        }
    };
    const renderText = (label) => {
        var _a;
        switch (label) {
            case 'Name of the organization':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', "data-testid": 'orgName', children: org === null || org === void 0 ? void 0 : org.name }) }));
            case 'Company website':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.companyWebsite }) }));
            case 'Client concierge':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: clientConciergeOnNewOrg
                            ? `${clientConciergeOnNewOrg.firstName} ${clientConciergeOnNewOrg.lastName}`
                            : ((_a = org === null || org === void 0 ? void 0 : org.clientConcierge) === null || _a === void 0 ? void 0 : _a.identity) &&
                                `${org === null || org === void 0 ? void 0 : org.clientConcierge.firstName} ${org === null || org === void 0 ? void 0 : org.clientConcierge.lastName}` }) }));
            case 'Business Unit':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.businessUnit }) }));
            case 'Vertical Market':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.verticalMarket }) }));
            case 'Street Address':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.address1 }) }));
            case 'State':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.stateOrProvince }) }));
            case 'City':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.city }) }));
            case 'Zip Code':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.postalCode }) }));
            default:
                return _jsx(_Fragment, {});
        }
    };
    const renderBasedOnMode = (readOnlyCmp, editableCmp) => (editMode ? editableCmp : readOnlyCmp);
    const handleImageErrorMessage = (file, supportedFormats) => {
        const fileExtension = file.name
            .substring(file.name.lastIndexOf('.'))
            .toLowerCase();
        if (!supportedFormats.includes(fileExtension)) {
            setImageError(VALID_EXTENSIONS_ERROR_MESSAGE);
            return;
        }
        else if (file.size > validFileSize) {
            setImageError(VALID_SIZE_ERROR_MESSAGE);
            return;
        }
        else {
            setImageError('');
            return;
        }
    };
    const handleOrgEnable = (field, checked) => {
        const updatedApps = orgApps.map((orgApp) => {
            if (orgApp.app === field) {
                return Object.assign(Object.assign({}, orgApp), { enabled: checked });
            }
            else {
                return orgApp;
            }
        });
        setOrgApps(updatedApps);
    };
    const handleOrgExpires = (field, value) => {
        const updatedApps = orgApps.map((orgApp) => {
            if (orgApp.app === field) {
                return Object.assign(Object.assign({}, orgApp), { expiresOn: value ? new Date(value) : undefined });
            }
            else {
                return orgApp;
            }
        });
        setOrgApps(updatedApps);
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(Stack, { w: '100%', spacing: -2, children: [_jsxs(HStack, { w: '100%', alignItems: 'flex-start', spacing: 4, children: [_jsxs(Stack, { w: '30%', spacing: '18px', children: [renderBasedOnMode(renderText('Name of the organization'), _jsx(InputWithPopOver, { label: 'Name of the organization', register: nameOfOrgRegister, required: editMode &&
                                            requiredFields.includes('Name of the organization'), renderError: renderError })), _jsx(Box, { pl: 5, children: _jsx(LogoInput, { logoUrl: (_a = org === null || org === void 0 ? void 0 : org.smallLogoUrl) !== null && _a !== void 0 ? _a : '', logoRef: logoSmallRef, imageError: imageError, handleImageErrorMessage: handleImageErrorMessage, org: org, editMode: editMode }) }), renderBasedOnMode(renderText('Business Unit'), _jsx(FormDropdown, { required: editMode &&
                                            requiredFields.includes('Business Unit'), options: BusinessUnitOptions, defaultValue: org === null || org === void 0 ? void 0 : org.businessUnit, label: 'Business Unit', optionKey: 'business-unit', register: businessUnitRegister, renderError: renderError })), renderBasedOnMode(renderText('Vertical Market'), _jsx(FormDropdown, { required: editMode &&
                                            requiredFields.includes('Vertical Market'), options: VerticalMarketOptions, defaultValue: org === null || org === void 0 ? void 0 : org.verticalMarket, label: 'Vertical Market', optionKey: 'vertical-market', register: verticalMarketRegister, renderError: renderError }))] }), _jsxs(Stack, { w: '23%', spacing: '18px', children: [renderBasedOnMode(renderText('Street Address'), _jsx(InputWithPopOver, { label: 'Street Address', register: streetAddressRegister, required: editMode &&
                                            requiredFields.includes('Street Address'), renderError: renderError })), renderBasedOnMode(renderText('City'), _jsx(InputWithPopOver, { label: 'City', register: cityRegister, required: editMode && requiredFields.includes('City'), renderError: renderError })), renderBasedOnMode(renderText('State'), _jsx(FormDropdown, { required: editMode && requiredFields.includes('State'), options: states.map((s) => s.description), defaultValue: org === null || org === void 0 ? void 0 : org.stateOrProvince, label: 'State', optionKey: 'state', register: stateRegister, renderError: renderError })), renderBasedOnMode(renderText('Zip Code'), _jsx(InputWithPopOver, { label: 'Zip Code', register: zipCodeRegister, required: editMode &&
                                            requiredFields.includes('Zip Code'), renderError: renderError })), renderBasedOnMode(renderText('Company website'), _jsx(InputWithPopOver, { label: 'Company website', register: companyWebsiteRegister, required: editMode &&
                                            requiredFields.includes('Company website'), renderError: renderError }))] }), _jsx(Stack, { w: '42%', spacing: '18px', children: _jsx(UsersAccess, { canEditAppContext: canEditAppContext, editMode: editMode, newPrimaryContactIdIdentity: newPrimaryContactIdIdentity, org: org, orgAdmins: orgAdmins, renderText: renderText, requiredFields: requiredFields, setAddAdminOrConcierge: setAddAdminOrConcierge, setNewPrimaryContactIdIdentity: setNewPrimaryContactIdIdentity, setClientConciergeOnNewOrg: setClientConciergeOnNewOrg, localAdminsOnNewOrg: localAdminsOnNewOrg, setLocalAdminsOnNewOrg: setLocalAdminsOnNewOrg, primaryContactOnNewOrgEmail: primaryContactOnNewOrgEmail, setPrimaryContactOnNewOrgEmail: setPrimaryContactOnNewOrgEmail, canAddLocalAdmin: canAddLocalAdmin, currentUser: currentUser, onSelectOrganization: onSelectOrganization, upsertOrganization: upsertOrganization, orgApps: orgApps, handleOrgEnable: handleOrgEnable, handleOrgExpires: handleOrgExpires }) })] }), _jsxs(Box, { mt: '20px !important', children: [editMode && (_jsxs(FormControl, { isRequired: true, mt: '5px', mb: '25px', ml: '-18px', children: [' ', _jsxs(FormLabel, { fontSize: '14', color: appColors.TEXT_COLOR, children: ["Required field marked with", ' '] })] })), canEdit && (_jsxs(HStack, { mb: '20px', children: [org && (_jsx(Button, { variant: variants.editBtn, onClick: () => {
                                            setEditing(!editMode);
                                            setImageError('');
                                            const resetApps = org.apps.map((app) => (Object.assign(Object.assign({}, app), { expiresOn: app.expiresOn
                                                    ? app.expiresOn
                                                    : undefined })));
                                            setOrgApps(resetApps);
                                            // reset the input value to allow selecting the same file again
                                            if (logoSmallRef.current) {
                                                logoSmallRef.current.value = '';
                                            }
                                        }, children: !editMode ? 'Edit' : 'Cancel' })), editMode && (_jsx(SaveNew, { org: org, editMode: editMode, isSubmitting: isLoading || imageError ? true : false, currentUser: currentUser, onSelectOrganization: onSelectOrganization }))] }))] })] }), _jsx(LoadingWithControl, { isLoading: isLoading })] }));
};
const NAME_OF_ORG_KEY = 'nameOfOrg';
const BUSINESS_UNIT_KEY = 'businessUnit';
const VERTICAL_MARKET_KEY = 'verticalMarket';
const STREET_ADDRESS_KEY = 'address.address1';
const CITY_KEY = 'address.city';
const STATE_KEY = 'address.state';
const ZIP_CODE_KEY = 'address.zip';
const COMPANY_WEBSITE_KEY = 'companyWebsite';
