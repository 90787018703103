import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Td, Tr, } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { useReports } from '../../../components/context/ReportsContext';
import { appColors } from '../../../config/constants';
export const ReportTableRow = ({ showCustomer, reportName, appContext, reportId, reportKey, workspaceId, popoverMenu, }) => {
    const { selectReport } = useReports();
    const renderActionTd = () => {
        return (_jsxs(Popover, { placement: 'left', children: [_jsx(PopoverTrigger, { children: _jsx(IconButton, { boxSize: '25px', icon: _jsx(BsThreeDots, {}), "aria-label": 'More Menu', variant: 'ghost', onClick: () => {
                            selectReport(reportId);
                        } }) }), _jsx(PopoverContent, { width: 'auto', border: '1px solid', borderColor: appColors.BORDER_SEC_LIGHT_GRAY, boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)', bg: appColors.BACKGROUND_LIGHT_GRAY, children: _jsx(PopoverBody, { children: popoverMenu }) })] }));
    };
    return (_jsxs(Tr, { h: '48px', boxSizing: 'border-box', children: [_jsx(Td, { borderColor: appColors.BORDER_LIGHT_GRAY, textAlign: 'center', children: _jsx(Checkbox, { isDisabled: true, isChecked: showCustomer, iconColor: appColors.SEC_ORANGE }) }), _jsx(Td, { borderColor: appColors.BORDER_LIGHT_GRAY, children: reportName }), _jsx(Td, { borderColor: appColors.BORDER_LIGHT_GRAY, color: appColors.PRIM_BLUE, fontWeight: 500, children: appContext }), _jsx(Td, { borderColor: appColors.BORDER_LIGHT_GRAY, children: reportKey }), _jsx(Td, { borderColor: appColors.BORDER_LIGHT_GRAY, children: workspaceId }), _jsx(Td, { borderColor: appColors.BORDER_LIGHT_GRAY, children: renderActionTd() })] }));
};
