import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
export const SystemAdminTable = ({ tableName, tableHeaders, checkbox, children, }) => {
    const renderHeaders = () => {
        return tableHeaders.map((header) => {
            return _jsx(Td, { children: header }, `${tableName} ${header}`);
        });
    };
    return (_jsxs(Table, { children: [_jsx(Thead, { layerStyle: 'thead', children: _jsxs(Tr, { children: [checkbox && (_jsx(Td, { children: _jsx(Checkbox, {}) })), renderHeaders(), _jsx(Td, {})] }) }), _jsx(Tbody, { children: children })] }));
};
