var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { createContext, useContext, useEffect, useState } from 'react';
import { usePlatformOrgApi } from '../../services/api/platformOrgApiHook';
export const ReportsContext = createContext({});
export const useReports = () => useContext(ReportsContext);
export function ReportsProvider({ children, initialReports, }) {
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const [reports, setReports] = useState(initialReports !== null && initialReports !== void 0 ? initialReports : []);
    const [currentReport, setCurrentReport] = useState(undefined);
    const { assessmentRefresh, registerReport, userReports, removeReport } = usePlatformOrgApi();
    const refreshReports = (orgId) => __awaiter(this, void 0, void 0, function* () {
        const reports = yield userReports(orgId);
        setReports(reports);
    });
    useEffect(() => {
        if (!initialReports && selectedOrganizationIdentity) {
            refreshReports(selectedOrganizationIdentity);
        }
    }, [selectedOrganizationIdentity]);
    const registerAndRefresh = (data) => __awaiter(this, void 0, void 0, function* () {
        yield registerReport(data);
        if (selectedOrganizationIdentity) {
            yield refreshReports(selectedOrganizationIdentity);
        }
    });
    const removeAndRefresh = (reportId) => __awaiter(this, void 0, void 0, function* () {
        yield removeReport(reportId);
        if (selectedOrganizationIdentity) {
            yield refreshReports(selectedOrganizationIdentity);
        }
    });
    const refreshAssessmentAndReports = () => __awaiter(this, void 0, void 0, function* () {
        if (selectedOrganizationIdentity) {
            yield assessmentRefresh(selectedOrganizationIdentity);
        }
    });
    const selectReport = (reportId) => {
        const currentReport = reports.find((report) => report.reportId === reportId);
        setCurrentReport(currentReport);
    };
    return (_jsx(ReportsContext.Provider, { value: {
            reports,
            registerReport: registerAndRefresh,
            currentReport,
            selectReport,
            removeReport: removeAndRefresh,
            refreshAssessment: refreshAssessmentAndReports,
        }, children: children }));
}
