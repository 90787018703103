export const validateGUID = (e, field, setGUIDValidation) => {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const validFormat = guidRegex.test(e.target.value);
    setGUIDValidation((prevState) => (Object.assign(Object.assign({}, prevState), { [field]: validFormat })));
};
export const validateName = (value, array, onInvalidName, onValidName) => {
    const normalizeName = (name) => {
        return name.toLowerCase().replace(/\s+/g, '');
    };
    const normalizedValue = normalizeName(value);
    const normalizedArray = array.map((item) => normalizeName(item.name));
    const isInvalid = normalizedArray.some((item) => item === normalizedValue);
    if (isInvalid) {
        onInvalidName();
    }
    else {
        onValidName();
    }
};
