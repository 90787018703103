import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, Tbody, Text, Th, Thead, Tr, useDisclosure, } from '@chakra-ui/react';
import { createColumnHelper, flexRender } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useReports } from '../../../components/context/ReportsContext';
import { appColors } from '../../../config/constants';
import { DeleteModal } from '../../../sharedComponents/DeleteModal';
import { deleteDescription, useDeleteReport, } from '../../../utils/handleDelete';
import { useDataTable } from '../../../utils/sortingAndFiltering';
import ActionsBodyReportModal from './ActionsBodyReportModal';
import { ReportModal } from './ReportModal';
import { ReportTableRow } from './ReportTableRow';
const columnHelper = createColumnHelper();
export const ReportTable = ({ orgIdentity, onOpen, isOpen, onClose, }) => {
    const { reports, currentReport } = useReports();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete, } = useDisclosure();
    const handleDeleteReport = useDeleteReport();
    const convertSnapshotToTableData = (reports) => {
        return reports.flatMap((snapshot) => ({
            showCustomer: snapshot.isPublished,
            reportName: snapshot.reportName,
            appContext: snapshot.appContext,
            reportId: snapshot.reportId,
            reportKey: snapshot.reportKey,
            workspaceId: snapshot.workspaceKey,
        }));
    };
    const dataSnapshot = useMemo(() => convertSnapshotToTableData(reports), [reports]);
    const table = useDataTable({
        data: dataSnapshot,
        columns: useMemo(() => [
            columnHelper.accessor('showCustomer', {
                id: 'showCustomer',
                cell: (info) => {
                    return _jsx(Text, { children: info.row.original.showCustomer });
                },
                header: () => 'Show Customer',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reportName', {
                id: 'reportName',
                cell: (info) => {
                    return _jsx(Text, { children: info.row.original.reportName });
                },
                header: () => 'Report Name',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('appContext', {
                id: 'appContext',
                cell: (info) => {
                    return _jsx(Text, { children: info.row.original.appContext });
                },
                header: () => 'App Context',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reportKey', {
                id: 'reportKey',
                cell: (info) => {
                    return _jsx(Text, { children: info.row.original.reportKey });
                },
                header: () => 'Report ID',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('workspaceId', {
                id: 'workspaceId',
                cell: (info) => {
                    return _jsx(Text, { children: info.row.original.workspaceId });
                },
                header: () => 'WorkSpace ID',
                footer: (info) => info.column.id,
            }),
            {
                id: 'actionsColumn',
                header: () => '',
                footer: () => '',
                accessor: '',
                cell: () => null,
            },
        ], []),
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { border: `1px solid ${appColors.BORDER_LIGHT_GRAY}`, children: [_jsx(Thead, { layerStyle: 'thead', children: table.getHeaderGroups().map((headerGroup) => (_jsx(Tr, { children: headerGroup.headers.map((header) => (_jsx(Th, { textTransform: 'capitalize', fontSize: '14px', fontWeight: 400, color: appColors.BLACK, borderColor: appColors.BORDER_LIGHT_GRAY, whiteSpace: 'nowrap', children: flexRender(header.column.columnDef.header, header.getContext()) }, header.id))) }, headerGroup.id))) }), reports && (_jsx(Tbody, { children: table.getRowModel().rows.map((row) => (_jsx(ReportTableRow, { reportId: row.original.reportId, showCustomer: row.original.showCustomer, reportName: row.original.reportName, appContext: row.original.appContext, reportKey: row.original.reportKey, workspaceId: row.original.workspaceId, popoverMenu: _jsx(ActionsBodyReportModal, { onOpenEdit: onOpen, onOpenDelete: onOpenDelete }) }, row.id))) }))] }), orgIdentity && (_jsx(ReportModal, { orgIdentity: orgIdentity, isOpen: isOpen, onClose: onClose })), currentReport && (_jsx(DeleteModal, { isOpen: isOpenDelete, onClose: onCloseDelete, deleteItem: () => handleDeleteReport(currentReport, onCloseDelete), title: 'Delete Report', description: deleteDescription(currentReport.reportName) }))] }));
};
