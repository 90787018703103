import { IAppNames } from '@frontend/domain/models/App';
import { OrganizationRoleEnum, SystemRoleEnum, } from '../../objects/UserData';
export const useAppAccess = (user, org) => {
    const hasAccess = (maybe) => {
        var _a, _b, _c, _d, _e;
        if (!user) {
            return false;
        }
        if (!org) {
            return false;
        }
        if ((_a = org.apps) === null || _a === void 0 ? void 0 : _a.some((app) => app.app === maybe && app.enabled)) {
            if (user.systemRole === SystemRoleEnum.SUPER_ADMIN) {
                return true;
            }
            if (user.systemRole === SystemRoleEnum.ORG_APPROVER) {
                return true;
            }
            if ((_b = user.organizationRoles) === null || _b === void 0 ? void 0 : _b.some((role) => role.organizationIdentity === (org === null || org === void 0 ? void 0 : org.identity) &&
                role.role === OrganizationRoleEnum.LOCAL_ADMIN)) {
                return true;
            }
            if ((_c = user.organizationRoles) === null || _c === void 0 ? void 0 : _c.some((role) => role.organizationIdentity === (org === null || org === void 0 ? void 0 : org.identity) &&
                role.role === OrganizationRoleEnum.CLIENT_CONCIERGE)) {
                return true;
            }
            return ((_e = (_d = user.apps) === null || _d === void 0 ? void 0 : _d.some((app) => app.app === maybe &&
                app.organizationIdentity === (org === null || org === void 0 ? void 0 : org.identity))) !== null && _e !== void 0 ? _e : false);
        }
        return false;
    };
    return {
        [IAppNames.REPORTS]: hasAccess(IAppNames.REPORTS),
        [IAppNames.FCA]: hasAccess(IAppNames.FCA),
        [IAppNames.PLANNER]: hasAccess(IAppNames.PLANNER),
        [IAppNames.PIQ]: hasAccess(IAppNames.PIQ),
    };
};
