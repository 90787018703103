var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSettingsService } from '../../services/SettingsService';
import { useDependencies } from '../Dependencies/DependenciesContext';
import { PlannerSettingsContext } from './PlannerSettingsContext';
export function PlannerSettingsProvider({ children, orgIdentity, }) {
    const { mysiteIQApi, platformApi } = useDependencies();
    const { settings } = useSettingsService({
        mysiteIQApi,
        platformApi,
    });
    const [orgFundingTypes, setOrgFundingTypes] = useState([]);
    const [orgProjectTypes, setOrgProjectTypes] = useState([]);
    const [orgPrioritizationAlgorithms, setOrgPrioritizationAlgorithms] = useState([]);
    const [orgEscalationFactor, setOrgEscalationFactor] = useState(undefined);
    const [orgAgeAdjustedCondition, setOrgAgeAdjustedCondition] = useState(false);
    const [orgFacilityGroupers, setOrgFacilityGroupers] = useState([]);
    const [priorityCriteria, setPriorityCriteria] = useState([]);
    const toast = useToast();
    const noOrgToast = {
        title: 'Failed to update setting',
        status: 'error',
        isClosable: true,
    };
    const upsertFundingType = (data) => __awaiter(this, void 0, void 0, function* () {
        if (orgIdentity) {
            return settings
                .update(orgIdentity, { fundingTypes: [data] }, 'fundingTypes')
                .then((response) => {
                if (response && response.fundingTypes) {
                    setOrgFundingTypes(response.fundingTypes);
                    return response.fundingTypes;
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
            return undefined;
        }
    });
    const deleteFundingType = (data) => __awaiter(this, void 0, void 0, function* () {
        const ftToDelete = {
            identity: data.identity,
            name: data.name,
            remove: true,
        };
        if (orgIdentity) {
            settings
                .update(orgIdentity, { fundingTypes: [ftToDelete] }, 'fundingTypes')
                .then((response) => {
                if (response && response.fundingTypes) {
                    setOrgFundingTypes(response.fundingTypes);
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
        }
    });
    const upsertProjectType = (data) => __awaiter(this, void 0, void 0, function* () {
        const projectType = {
            identity: data.identity,
            name: data.name,
            algorithmId: data.algorithmId,
        };
        if (orgIdentity) {
            return settings
                .update(orgIdentity, {
                projectTypes: [projectType],
            }, 'projectTypes')
                .then((response) => {
                if (response && response.projectTypes) {
                    setOrgProjectTypes(response.projectTypes);
                    return response.projectTypes;
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
            return undefined;
        }
    });
    const deleteProjectType = (data) => __awaiter(this, void 0, void 0, function* () {
        const ptToDelete = {
            identity: data.identity,
            name: data.name,
            algorithmId: data.algorithmId,
            remove: true,
        };
        if (orgIdentity) {
            settings
                .update(orgIdentity, { projectTypes: [ptToDelete] }, 'projectTypes')
                .then((response) => {
                if (response && response.projectTypes) {
                    setOrgProjectTypes(response.projectTypes);
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
        }
    });
    const upsertPrioritizationAlgorithm = (data) => __awaiter(this, void 0, void 0, function* () {
        if (orgIdentity) {
            return settings
                .update(orgIdentity, { prioritizationAlgorithms: data }, 'prioritizationAlgorithms')
                .then((response) => {
                if (response && response.prioritizationAlgorithms) {
                    setOrgPrioritizationAlgorithms(response.prioritizationAlgorithms);
                    return response.prioritizationAlgorithms;
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
            return undefined;
        }
    });
    // eventually pass in the algorithm id to get correct algorithm criteria
    const selectPrioritizationCriteriaForProject = () => {
        if (orgPrioritizationAlgorithms.length > 0) {
            return orgPrioritizationAlgorithms[0].criteria;
        }
        else {
            return [];
        }
    };
    // this will go away once all projects are fetched from platformAPI
    const calculatePrioritizationScore = (priorityScores, prioritizationCriteria) => {
        let weightedScore = 0;
        prioritizationCriteria.forEach((criteria) => {
            var _a;
            const score = ((_a = priorityScores.find((score) => score.prioritizationWeightIdentity === criteria.identity)) === null || _a === void 0 ? void 0 : _a.score) || 0;
            weightedScore += score * criteria.weight;
        });
        if (isNaN(weightedScore)) {
            return 0;
        }
        const averagedScore = Math.round(weightedScore * 10) / 10;
        return averagedScore;
    };
    const upsertEscalationFactor = (data) => __awaiter(this, void 0, void 0, function* () {
        if (orgIdentity) {
            return settings
                .update(orgIdentity, { escalationFactor: data }, 'escalationFactor')
                .then((response) => {
                if (response) {
                    setOrgEscalationFactor(response.escalationFactor);
                    return response.escalationFactor;
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
            return undefined;
        }
    });
    const generalUpdateEscalationFactor = (data) => {
        if (data.escalation === 'None') {
            if (orgEscalationFactor) {
                upsertEscalationFactor({
                    escalation: 0,
                    remove: true,
                });
            }
        }
        else {
            const formattedEscalation = +data.escalation / 100;
            if (orgEscalationFactor) {
                if (formattedEscalation !== orgEscalationFactor.escalation) {
                    upsertEscalationFactor({
                        escalation: formattedEscalation,
                    });
                }
            }
            else {
                upsertEscalationFactor({
                    escalation: formattedEscalation,
                });
            }
        }
    };
    const updateFacilityGrouperName = (updatedFacilityGrouper) => __awaiter(this, void 0, void 0, function* () {
        if (orgIdentity) {
            return settings
                .update(orgIdentity, { facilityGroupers: [updatedFacilityGrouper] }, 'facilityGroupers')
                .then((response) => {
                if (response && response.facilityGroupers) {
                    setOrgFacilityGroupers(response.facilityGroupers);
                    return response.facilityGroupers;
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
            return undefined;
        }
    });
    const updateAgeAdjustedCondition = (adjustedToggle) => __awaiter(this, void 0, void 0, function* () {
        if (orgIdentity) {
            return settings
                .update(orgIdentity, { useAgeAdjustedCondition: adjustedToggle }, 'useAgeAdjustedCondition')
                .then((response) => {
                if (response &&
                    response.useAgeAdjustedCondition !== undefined) {
                    setOrgAgeAdjustedCondition(response.useAgeAdjustedCondition);
                }
            })
                .catch(() => undefined);
        }
        else {
            toast(noOrgToast);
            return undefined;
        }
    });
    const refreshPriorityCriteria = () => {
        if (orgIdentity) {
            settings.fetch(orgIdentity).then((response) => {
                if (response) {
                    setPriorityCriteria(response.priorityCriteria || []);
                }
            });
        }
    };
    useEffect(() => {
        if (orgIdentity) {
            settings.fetch(orgIdentity).then((response) => {
                if (response) {
                    setOrgFundingTypes(response.fundingTypes || []);
                    setOrgProjectTypes(response.projectTypes || []);
                    setOrgPrioritizationAlgorithms(response.prioritizationAlgorithms || []);
                    setPriorityCriteria(response.priorityCriteria || []);
                    setOrgFacilityGroupers(response.facilityGroupers || []);
                    setOrgEscalationFactor(response.escalationFactor);
                    setOrgAgeAdjustedCondition(response.useAgeAdjustedCondition || false);
                }
            });
        }
    }, [orgIdentity]);
    return (_jsx(PlannerSettingsContext.Provider, { value: {
            orgFundingTypes,
            upsertFundingType,
            deleteFundingType,
            orgProjectTypes,
            upsertProjectType,
            deleteProjectType,
            orgPrioritizationAlgorithms,
            priorityCriteria,
            upsertPrioritizationAlgorithm,
            selectPrioritizationCriteriaForProject,
            calculatePrioritizationScore,
            orgEscalationFactor,
            generalUpdateEscalationFactor,
            orgAgeAdjustedCondition,
            updateAgeAdjustedCondition,
            orgFacilityGroupers,
            updateFacilityGrouperName,
            refreshPriorityCriteria,
        }, children: children }));
}
