import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, IconButton, Td, Text, Tooltip, Tr, useDisclosure, } from '@chakra-ui/react';
import { EditIcon2 } from '../../../../config/icons';
import { variants } from '@frontend/design-system/theme/theme';
import { InfoWarningModal } from '../../../../sharedComponents/InfoWarningModal';
export const FacilityAdditionTableRow = ({ facilityAddition, onOpenEditFacilityAddition, setFacilityAdditionToEdit, canEdit, }) => {
    const { isOpen: isOpenWarning, onClose: onCloseWarning } = useDisclosure();
    const renderActionTd = () => {
        return (_jsx(HStack, { children: _jsx(Tooltip, { label: 'Edit facility', openDelay: 300, bg: 'brand.primBlue', children: _jsx(IconButton, { onClick: () => {
                        setFacilityAdditionToEdit(facilityAddition);
                        onOpenEditFacilityAddition();
                    }, boxSize: '26px', icon: _jsx(EditIcon2, {}), "aria-label": 'edit facility', variant: variants.transparentIcoBtn }) }) }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(InfoWarningModal, { isOpen: isOpenWarning, onClose: onCloseWarning, children: _jsxs(_Fragment, { children: [_jsx(Text, { children: "You are about to remove from this organization. Are you sure you want to continue?" }), _jsx(HStack, { justifyContent: 'space-between', children: _jsx(Button, { onClick: onCloseWarning, variant: variants.orangeTransparentBtn, w: '48%', children: "Cancel" }) })] }) }), _jsxs(Tr, { h: '50px', boxSizing: 'border-box', children: [_jsx(Td, { py: 0, children: facilityAddition.yearBuilt }), _jsx(Td, { py: 0, children: facilityAddition.name }), _jsx(Td, { py: 0, children: facilityAddition.squareFootage }), canEdit && _jsx(Td, { py: 0, children: renderActionTd() })] })] }));
};
