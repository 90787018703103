import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react';
import React from 'react';
export function Dropdown({ dropdownValues, label, handleChange, value, isLoading, bgColor, }) {
    const getDropdownValues = (dropdownValues) => {
        return dropdownValues.map((item) => {
            return (_jsx("option", { value: item.value, children: item.label }, `select.${item.label}`));
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Flex, { gap: 5, m: 5, children: _jsxs(InputGroup, { children: [_jsx(InputLeftAddon, { borderLeftRadius: '8px', color: 'white', background: bgColor !== null && bgColor !== void 0 ? bgColor : 'brand.primBlue', px: 3, children: label }), isLoading ? (_jsx(Select, { border: '1px solid black', minW: '239px', borderLeftRadius: 0, value: 'loading', isReadOnly: true, isDisabled: true, children: _jsx("option", { disabled: true, value: 'loading', children: "Loading..." }) })) : (_jsxs(Select, { border: '1px solid black', "data-testid": 'dropdown-select', minW: '235px', onChange: (event) => handleChange(event.target.value), borderLeftRadius: 0, value: value, children: [_jsxs("option", { disabled: true, value: '', children: ["Select ", label] }), getDropdownValues(dropdownValues)] }))] }) }) }));
}
