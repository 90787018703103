var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, useToast } from '@chakra-ui/react';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { useForm } from 'react-hook-form';
import { PageLayout } from '../../../components/layouts/PageLayout';
import { BasicInfo } from '../BasicInfo';
export const UserProfilePage = () => {
    const { user } = useCurrentUser();
    const toast = useToast();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm();
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        toast({
            title: 'Save failed',
            description: 'Cannot update user through this page.',
            status: 'error',
            isClosable: true,
        });
    });
    return (_jsx(PageLayout, { title: 'Profile', hideAddOrgButton: true, hideOrgsDropdown: true, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), style: { width: '100%' }, children: _jsx(Stack, { width: '100%', height: '95%', spacing: 10, children: user && (_jsx(BasicInfo, { user: user, org: organizationDetails, register: register, setValue: setValue, errors: errors })) }) }) }));
};
