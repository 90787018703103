var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useUser } from '@frontend/domain/contexts/User/UserDataHook';
import { AppLoading } from '@frontend/shared/src/components/Loading/AppLoading';
import { useEffect } from 'react';
const ActivatePage = () => {
    const { activateUser } = useUser();
    const queryParams = new URLSearchParams(window.location.search);
    const registrationCode = queryParams.get('key');
    const emailAddress = queryParams.get('email');
    useEffect(() => {
        if (!registrationCode || !emailAddress)
            return;
        const userActivation = () => __awaiter(void 0, void 0, void 0, function* () {
            const activateUserObj = {
                registrationCode,
                emailAddress,
            };
            const response = yield activateUser(activateUserObj);
            if (response) {
                window.location.href = response;
            }
        });
        userActivation();
    }, [registrationCode, emailAddress]);
    return _jsx(AppLoading, {});
};
export default ActivatePage;
