var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useToast, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { IAppNames } from '@frontend/domain/models/App';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { appColors } from '../../../config/constants';
import { ModalType, OrganizationRoleEnum, SystemRoleEnum, } from '../../../objects/UserData';
import { usePlatformUserApi } from '../../../services/api/platformUserApiHook';
import { getUserByEmail } from '../../../services/api/profilePageAPI';
export const AddAdminOrConciergeModal = ({ org, modalType, setAddAdminOrConcierge, isOpen, onClose, setClientConciergeOnNewOrg, localAdminsOnNewOrg, setLocalAdminsOnNewOrg, onSelectOrganization, upsertOrganization, }) => {
    const [newEmail, setNewEmail] = useState(undefined);
    const [onUserPage, setOnUserPage] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState(undefined);
    const { register, handleSubmit, reset, setValue } = useForm();
    const { sendInvite, configureUser, viewUser, offboardUser } = usePlatformUserApi();
    const toast = useToast();
    const firstNameRegister = register(FIRST_NAME_KEY, { required: true });
    const lastNameRegister = register(LAST_NAME_KEY, { required: true });
    const jobTitleRegister = register(JOB_TITLE_KEY);
    const phoneNumberRegister = register(PHONE_NUMBER_KEY);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (userToUpdate) {
            setValue(FIRST_NAME_KEY, (_a = userToUpdate.firstName) !== null && _a !== void 0 ? _a : '');
            setValue(LAST_NAME_KEY, (_b = userToUpdate.lastName) !== null && _b !== void 0 ? _b : '');
            setValue(JOB_TITLE_KEY, (_c = userToUpdate.jobTitle) !== null && _c !== void 0 ? _c : '');
            setValue(PHONE_NUMBER_KEY, (_d = userToUpdate.phoneNumber) !== null && _d !== void 0 ? _d : '');
        }
    }, [setValue, userToUpdate]);
    const renderInput = (label, register, required, inputType, defaultValue) => {
        return (_jsxs(FormControl, { children: [_jsxs(HStack, { children: [_jsx(FormLabel, { mr: '3px', children: label }), required && (_jsx("p", { style: {
                                color: 'red',
                                marginLeft: '0',
                                marginBottom: '9px',
                            }, children: "*" }))] }), _jsx(Input, Object.assign({ type: inputType ? inputType : 'text', isDisabled: userToUpdate !== undefined, defaultValue: defaultValue ? defaultValue : '', placeholder: label }, register))] }));
    };
    const renderEmailInput = (label) => {
        return (_jsxs(FormControl, { children: [_jsxs(HStack, { children: [_jsx(FormLabel, { mr: '3px', children: label }), _jsx("p", { style: {
                                color: 'red',
                                marginLeft: '0',
                                marginBottom: '9px',
                            }, children: "*" })] }), _jsx(Input, { type: 'email', placeholder: label, isDisabled: onUserPage, defaultValue: onUserPage ? newEmail : '', onChange: (e) => {
                        setNewEmail(e.target.value);
                    } })] }));
    };
    const renderFirstPage = () => {
        return renderEmailInput('Email');
    };
    const renderUserPage = () => {
        return (_jsxs(_Fragment, { children: [userToUpdate && (_jsxs(Text, { color: appColors.GREEN, fontSize: '10px', children: ["This user already exists in our DB.", ' '] })), userToUpdate
                    ? renderInput('First Name', firstNameRegister, true, userToUpdate === null || userToUpdate === void 0 ? void 0 : userToUpdate.firstName)
                    : renderInput('First Name', firstNameRegister, true), userToUpdate
                    ? renderInput('Last Name', lastNameRegister, true, userToUpdate === null || userToUpdate === void 0 ? void 0 : userToUpdate.lastName)
                    : renderInput('Last Name', lastNameRegister, true), userToUpdate
                    ? renderInput('Job Title', jobTitleRegister, false, userToUpdate === null || userToUpdate === void 0 ? void 0 : userToUpdate.jobTitle)
                    : renderInput('Job Title', jobTitleRegister, true), renderEmailInput('Email'), userToUpdate
                    ? renderInput('Phone', phoneNumberRegister, false, 'tel', userToUpdate === null || userToUpdate === void 0 ? void 0 : userToUpdate.phoneNumber)
                    : renderInput('Phone', phoneNumberRegister, false, 'tel')] }));
    };
    const userExists = () => __awaiter(void 0, void 0, void 0, function* () {
        if (newEmail) {
            try {
                const user = yield getUserByEmail(newEmail);
                setUserToUpdate(user);
            }
            catch (_a) {
                setUserToUpdate(undefined);
            }
        }
    });
    const handleSubmitLA = (data, org) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        if (userToUpdate && userToUpdate.id) {
            try {
                yield configureUser({
                    userId: userToUpdate.userIdentity,
                    systemRole: userToUpdate.systemRole,
                    firstName: userToUpdate.firstName,
                    lastName: userToUpdate.lastName,
                    phoneNumber: userToUpdate.phoneNumber,
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    jobTitle: userToUpdate.jobTitle,
                    organizationRoles: [
                        {
                            organizationId: org.id,
                            organizationIdentity: org.identity,
                            role: OrganizationRoleEnum.LOCAL_ADMIN,
                            unassign: false,
                        },
                    ],
                });
                onSelectOrganization(org.identity);
                onEnd();
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
            }
            catch (error) {
                console.error('Add LA error: ', error);
                if (error instanceof AxiosError) {
                    toast({
                        title: 'Save failed',
                        description: (_b = error.response) === null || _b === void 0 ? void 0 : _b.data,
                        status: 'error',
                        duration: 10000,
                        isClosable: true,
                    });
                }
                else {
                    toast({
                        title: 'Save failed',
                        description: 'The server encountered an issue; please try again later.',
                        status: 'error',
                        isClosable: true,
                    });
                }
            }
        }
        else {
            try {
                yield sendInvite({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    jobTitle: data.jobTitle,
                    phoneNumber: data.phoneNumber,
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    emailAddress: newEmail,
                    systemRole: SystemRoleEnum.USER,
                    organizationRoles: [
                        {
                            organizationId: org.id,
                            organizationIdentity: org.identity,
                            role: OrganizationRoleEnum.LOCAL_ADMIN,
                            unassign: false,
                        },
                    ],
                });
                onSelectOrganization(org.identity);
                onEnd();
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
            }
            catch (error) {
                console.error('Create LA error: ', error);
                if (error instanceof AxiosError) {
                    toast({
                        title: 'Save failed',
                        description: (_c = error.response) === null || _c === void 0 ? void 0 : _c.data,
                        status: 'error',
                        duration: 10000,
                        isClosable: true,
                    });
                }
                else {
                    toast({
                        title: 'Save failed',
                        description: 'Please check all the fields are filled in correctly or try again later.',
                        status: 'error',
                        isClosable: true,
                    });
                }
            }
        }
    });
    const handleSubmitCC = (data, org) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        if (org &&
            org.clientConcierge &&
            org.clientConcierge.email &&
            (org === null || org === void 0 ? void 0 : org.clientConcierge.email) !== '') {
            try {
                const oldCC = yield viewUser(org.clientConcierge.identity);
                if (oldCC.organizationRoles &&
                    ((_d = oldCC.organizationRoles) === null || _d === void 0 ? void 0 : _d.length) > 1) {
                    if (oldCC.userIdentity) {
                        yield offboardUser(oldCC.userIdentity, org.identity);
                    }
                }
                else if (oldCC.organizationRoles &&
                    ((_e = oldCC.organizationRoles) === null || _e === void 0 ? void 0 : _e.length) === 1) {
                    if (oldCC.systemRole === SystemRoleEnum.ORG_APPROVER ||
                        oldCC.systemRole === SystemRoleEnum.SUPER_ADMIN) {
                        if (oldCC.userIdentity) {
                            yield offboardUser(oldCC.userIdentity, org.identity);
                        }
                    }
                    else {
                        if (oldCC.userIdentity) {
                            yield offboardUser(oldCC.userIdentity);
                        }
                    }
                }
            }
            catch (error) {
                if (error instanceof AxiosError) {
                    toast({
                        title: 'Save failed',
                        description: (_f = error.response) === null || _f === void 0 ? void 0 : _f.data,
                        status: 'error',
                        duration: 10000,
                        isClosable: true,
                    });
                }
                else {
                    toast({
                        title: 'Save failed',
                        description: 'The server encountered an issue; please try again later.',
                        status: 'error',
                        isClosable: true,
                    });
                }
            }
        }
        if (userToUpdate && userToUpdate.id) {
            try {
                // TODO: Set this up as a transaction for all of the updates, etc.
                yield configureUser({
                    userId: userToUpdate.userIdentity,
                    systemRole: userToUpdate.systemRole,
                    firstName: userToUpdate.firstName,
                    lastName: userToUpdate.lastName,
                    phoneNumber: userToUpdate.phoneNumber,
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    jobTitle: userToUpdate.jobTitle,
                    organizationRoles: [
                        {
                            organizationId: org.id,
                            organizationIdentity: org.identity,
                            role: OrganizationRoleEnum.CLIENT_CONCIERGE,
                            unassign: false,
                        },
                    ],
                });
                yield upsertOrganization({
                    nameOfOrg: org.name,
                    businessUnit: org.businessUnit,
                    verticalMarket: org.verticalMarket,
                    address: {
                        address1: org.address1,
                        address2: org.address2,
                        city: org.city,
                        zip: org.postalCode,
                        state: org.stateOrProvince,
                    },
                    companyWebsite: org.companyWebsite,
                    reports: (_g = org.apps) === null || _g === void 0 ? void 0 : _g.find((app) => app.app === IAppNames.REPORTS),
                    fca: (_h = org.apps) === null || _h === void 0 ? void 0 : _h.find((app) => app.app === IAppNames.FCA),
                    planner: (_j = org.apps) === null || _j === void 0 ? void 0 : _j.find((app) => app.app === IAppNames.PLANNER),
                    piq: (_k = org.apps) === null || _k === void 0 ? void 0 : _k.find((app) => app.app === IAppNames.PIQ),
                }, undefined, org.apps, org.primaryContact, {
                    identity: userToUpdate.userIdentity,
                    firstName: userToUpdate.firstName,
                    lastName: userToUpdate.lastName,
                    email: userToUpdate.email,
                    phoneNumber: userToUpdate.phoneNumber,
                    jobTitle: userToUpdate.jobTitle,
                }, org.identity);
                onEnd();
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
            }
            catch (error) {
                console.error('Add CC error: ', error);
                if (error instanceof AxiosError) {
                    toast({
                        title: 'Save failed',
                        description: (_l = error.response) === null || _l === void 0 ? void 0 : _l.data,
                        status: 'error',
                        duration: 10000,
                        isClosable: true,
                    });
                }
                else {
                    toast({
                        title: 'Save failed',
                        description: 'The server encountered an issue; please try again later.',
                        status: 'error',
                        isClosable: true,
                    });
                }
            }
        }
        else {
            try {
                // TODO: Set this up as a transaction for all of the updates, etc.
                yield sendInvite({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    jobTitle: data.jobTitle,
                    phoneNumber: data.phoneNumber,
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    emailAddress: newEmail,
                    systemRole: SystemRoleEnum.USER,
                    organizationRoles: [
                        {
                            organizationId: org.id,
                            organizationIdentity: org.identity,
                            role: OrganizationRoleEnum.CLIENT_CONCIERGE,
                            unassign: false,
                        },
                    ],
                });
                //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const newUser = yield getUserByEmail(newEmail);
                yield upsertOrganization({
                    nameOfOrg: org.name,
                    businessUnit: org.businessUnit,
                    verticalMarket: org.verticalMarket,
                    address: {
                        address1: org.address1,
                        address2: org.address2,
                        city: org.city,
                        zip: org.postalCode,
                        state: org.stateOrProvince,
                    },
                    companyWebsite: org.companyWebsite,
                    reports: (_m = org.apps) === null || _m === void 0 ? void 0 : _m.find((app) => app.app === IAppNames.REPORTS),
                    fca: (_o = org.apps) === null || _o === void 0 ? void 0 : _o.find((app) => app.app === IAppNames.FCA),
                    planner: (_p = org.apps) === null || _p === void 0 ? void 0 : _p.find((app) => app.app === IAppNames.PLANNER),
                    piq: (_q = org.apps) === null || _q === void 0 ? void 0 : _q.find((app) => app.app === IAppNames.PIQ),
                }, undefined, org.apps, org.primaryContact, {
                    identity: newUser.userIdentity,
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    email: newUser.email,
                    phoneNumber: newUser.phoneNumber,
                    jobTitle: newUser.jobTitle,
                }, org.identity);
                onEnd();
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
            }
            catch (error) {
                console.error('Create CC error: ', error);
                if (error instanceof AxiosError) {
                    toast({
                        title: 'Save failed',
                        description: (_r = error.response) === null || _r === void 0 ? void 0 : _r.data,
                        status: 'error',
                        duration: 10000,
                        isClosable: true,
                    });
                }
                else {
                    toast({
                        title: 'Save failed',
                        description: 'Please check all the fields are filled in correctly or try again later.',
                        status: 'error',
                        isClosable: true,
                    });
                }
            }
        }
    });
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _s;
        if (org) {
            try {
                if (modalType === ModalType.admin) {
                    handleSubmitLA(data, org);
                }
                else {
                    handleSubmitCC(data, org);
                }
                onSelectOrganization(org.identity);
                onEnd();
            }
            catch (error) {
                if (error instanceof AxiosError) {
                    toast({
                        title: 'Save failed',
                        description: (_s = error.response) === null || _s === void 0 ? void 0 : _s.data,
                        status: 'error',
                        duration: 10000,
                        isClosable: true,
                    });
                }
                else {
                    toast({
                        title: 'Save failed',
                        description: 'Please check that all fields are entered correctly.',
                        status: 'error',
                        isClosable: true,
                    });
                }
            }
        }
        else {
            if (modalType === ModalType.admin) {
                const localAdmins = localAdminsOnNewOrg;
                if (userToUpdate && userToUpdate.id) {
                    localAdmins.push({
                        isNew: false,
                        userId: userToUpdate.userIdentity,
                        systemRole: userToUpdate.systemRole,
                        firstName: userToUpdate.firstName,
                        lastName: userToUpdate.lastName,
                        phoneNumber: userToUpdate.phoneNumber,
                        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        jobTitle: userToUpdate.jobTitle,
                        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        emailAddress: newEmail,
                    });
                    setLocalAdminsOnNewOrg(localAdmins);
                }
                else {
                    localAdmins.push({
                        isNew: true,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        jobTitle: data.jobTitle,
                        phoneNumber: data.phoneNumber,
                        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        emailAddress: newEmail,
                        systemRole: SystemRoleEnum.USER,
                    });
                    setLocalAdminsOnNewOrg(localAdmins);
                }
            }
            else {
                if (userToUpdate && userToUpdate.id) {
                    setClientConciergeOnNewOrg({
                        isNew: false,
                        userId: userToUpdate.userIdentity,
                        systemRole: userToUpdate.systemRole,
                        firstName: userToUpdate.firstName,
                        lastName: userToUpdate.lastName,
                        phoneNumber: userToUpdate.phoneNumber,
                        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        jobTitle: userToUpdate.jobTitle,
                        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        emailAddress: newEmail,
                    });
                }
                else {
                    setClientConciergeOnNewOrg({
                        isNew: true,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        jobTitle: data.jobTitle,
                        phoneNumber: data.phoneNumber,
                        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        emailAddress: newEmail,
                        systemRole: SystemRoleEnum.USER,
                    });
                }
            }
            onEnd();
        }
    });
    const runReset = () => {
        setUserToUpdate(undefined);
        setNewEmail(undefined);
        setOnUserPage(false);
        reset({
            firstName: '',
            lastName: '',
            jobTitle: '',
            email: '',
            phoneNumber: '',
        });
    };
    const onEnd = () => {
        setAddAdminOrConcierge(false);
        onClose();
        runReset();
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onEnd, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent
            // TODO: FIX SPACING
            , { 
                // TODO: FIX SPACING
                minW: 741, h: 495, borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: modalType === ModalType.admin
                                ? 'New Local Admin'
                                : 'New Client Concierge' }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { children: _jsx(Stack, { py: 2, spacing: 5, children: !onUserPage ? renderFirstPage() : renderUserPage() }) }), _jsx(ModalFooter, { pt: 5, children: !onUserPage ? (_jsx(Button, { type: 'button', w: '100%', variant: variants.gradientBtn, isDisabled: newEmail === undefined ||
                                    newEmail === '' ||
                                    !/^\S+@\S+$/i.test(newEmail), onClick: () => {
                                    userExists();
                                    setOnUserPage(true);
                                }, children: "Next" })) : (_jsx(Button, { w: '100%', type: 'submit', variant: variants.gradientBtn, isDisabled: newEmail === undefined || newEmail === '', children: "Add" })) })] }) })] }));
};
const FIRST_NAME_KEY = 'firstName';
const LAST_NAME_KEY = 'lastName';
const JOB_TITLE_KEY = 'jobTitle';
const PHONE_NUMBER_KEY = 'phoneNumber';
