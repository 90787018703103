import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Switch, Text } from '@chakra-ui/react';
import { ContainerWithHeader } from '@frontend/design-system/components/DataDisplay/ContainerWithHeader';
import { variants } from '@frontend/design-system/theme/theme';
import { useState } from 'react';
export const AgeAdjustedConditionToggle = ({ canEdit, hasAgeAdjustedCondition, updateAgeAdjustedCondition, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const inputId = 'age-adjusted-condition-toggle';
    return (_jsx(ContainerWithHeader, { header: {
            label: 'Age-Adjusted Condition',
        }, htmlFor: inputId, children: _jsxs(HStack, { justifyContent: 'space-between', border: '1px solid #E6E7E9', borderRadius: '2px', padding: 5, children: [_jsx(Text, { children: hasAgeAdjustedCondition ? 'On' : 'Off' }), _jsx(Switch, { id: inputId, defaultChecked: false, isChecked: hasAgeAdjustedCondition, onChange: () => {
                        setIsLoading(true);
                        updateAgeAdjustedCondition(!hasAgeAdjustedCondition).finally(() => setIsLoading(false));
                    }, colorScheme: 'blue', size: 'lg', variant: variants.blueTransparentBtn, pl: '10px', pr: '10px', isDisabled: !canEdit || isLoading })] }) }));
};
