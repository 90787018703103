import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, IconButton, Td, Text, Tooltip, Tr, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { EditIcon2 } from '../../../config/icons';
import { InfoWarningModal } from '../../../sharedComponents/InfoWarningModal';
export const SitesTableRow = ({ site, onOpenEditSite, setSiteToEdit, canEdit, }) => {
    const { isOpen: isOpenWarning, onClose: onCloseWarning } = useDisclosure();
    const [siteSquareFootage, setSiteSquareFootage] = useState(0);
    useEffect(() => {
        var _a;
        if (site && site.facilities && site.facilities.length > 0)
            setSiteSquareFootage((_a = site.facilities) === null || _a === void 0 ? void 0 : _a.reduce((total, facility) => {
                var _a;
                const squareFootage = (_a = facility.originalSquareFootage) !== null && _a !== void 0 ? _a : 0;
                const additionalSquareFootage = facility.additions.reduce((total, addition) => {
                    var _a;
                    const additionSquareFootage = (_a = addition.squareFootage) !== null && _a !== void 0 ? _a : 0;
                    return total + additionSquareFootage;
                }, 0);
                return total + squareFootage + additionalSquareFootage;
            }, 0));
    }, [site, setSiteSquareFootage]);
    const renderActionTd = () => {
        return (_jsx(HStack, { children: _jsx(Tooltip, { label: 'Edit site', openDelay: 300, bg: 'brand.primBlue', children: _jsx(IconButton, { onClick: () => {
                        setSiteToEdit(site);
                        onOpenEditSite();
                    }, boxSize: '26px', icon: _jsx(EditIcon2, {}), "aria-label": `edit site ${site.name}`, variant: variants.transparentIcoBtn }) }) }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(InfoWarningModal, { isOpen: isOpenWarning, onClose: onCloseWarning, children: _jsxs(_Fragment, { children: [_jsx(Text, { children: "You are about to remove from this organization. Are you sure you want to continue?" }), _jsx(HStack, { justifyContent: 'space-between', children: _jsx(Button, { onClick: onCloseWarning, variant: variants.orangeTransparentBtn, w: '48%', children: "Cancel" }) })] }) }), _jsxs(Tr, { h: '50px', boxSizing: 'border-box', children: [_jsx(Td, { py: 0, children: site.name }), _jsx(Td, { py: 0, children: site.address.address1 }), _jsx(Td, { py: 0, children: siteSquareFootage > 0 ? siteSquareFootage : '' }), canEdit && _jsx(Td, { py: 0, children: renderActionTd() })] })] }));
};
