import { IAppNames } from '@frontend/domain/models/App';
import cpIcon from '../images/CP_nav_icon.svg';
import fcaIcon from '../images/FCA_nav_icon.svg';
import portfolioIQIcon from '../images/PIQ_nav_icon.svg';
import reportsIcon from '../images/Reports_nav_icon.svg';
export const getAppNameAndLogo = (app) => {
    let icon = undefined;
    let name = undefined;
    switch (app) {
        case IAppNames.REPORTS:
            icon = reportsIcon;
            name = 'MySiteIQ';
            break;
        case IAppNames.FCA:
            icon = fcaIcon;
            name = 'FCA';
            break;
        case IAppNames.PLANNER:
            icon = cpIcon;
            name = 'Planner';
            break;
        case IAppNames.PIQ:
            icon = portfolioIQIcon;
            name = 'PortfolioIQ';
            break;
    }
    return { icon, name };
};
