import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';
export const PhoneInput = ({ label, data, onInputChange, required, constraints, }) => {
    const formatPhoneNumber = (phoneNumber) => {
        const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
        if (cleanedPhoneNumber.length <= 3) {
            return cleanedPhoneNumber;
        }
        else if (cleanedPhoneNumber.length <= 6) {
            return `(${cleanedPhoneNumber.slice(0, 3)}) ${cleanedPhoneNumber.slice(3)}`;
        }
        else {
            return `(${cleanedPhoneNumber.slice(0, 3)}) ${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(6, 10)}`;
        }
    };
    return (_jsxs(FormControl, { isRequired: required, children: [_jsx(FormLabel, { pl: '0px', children: label }), _jsx("div", { style: { position: 'relative' }, children: _jsx(Input, { position: 'unset', pointerEvents: 'auto', value: data, fontSize: '14px', placeholder: label, onChange: (e) => onInputChange(formatPhoneNumber(e.target.value)), maxLength: constraints === null || constraints === void 0 ? void 0 : constraints.maxLength }) })] }));
};
