import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, Table, Tbody, Td, Thead, Tr, useDisclosure, VStack, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { useState } from 'react';
import { useMyFacilitiesContext } from '../Facilities/MyFacilitiesContext';
import { AddSiteModal } from './AddSiteModal';
import { EditSiteModal } from './EditSiteModal';
import { SitesTableRow } from './SitesTableRow';
export const SitesTable = ({ canEdit }) => {
    const myFacilitiesContext = useMyFacilitiesContext();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const { sites } = myFacilitiesContext;
    const [siteToEdit, setSiteToEdit] = useState(undefined);
    const { isOpen: isOpenAddSite, onOpen: onOpenAddSite, onClose: onCloseAddSite, } = useDisclosure();
    const { isOpen: isOpenEditSite, onOpen: onOpenEditSite, onClose: onCloseEditSite, } = useDisclosure();
    const renderRows = () => {
        return (_jsx(_Fragment, { children: sites &&
                sites.map((site, i) => {
                    return (_jsx(SitesTableRow, { onOpenEditSite: onOpenEditSite, setSiteToEdit: setSiteToEdit, site: site, canEdit: canEdit }, `${site} ${i}`));
                }) }));
    };
    return (_jsxs(VStack, { w: '100%', alignItems: 'start', children: [canEdit && (_jsx(Button, { onClick: onOpenAddSite, w: '205px', h: '43px', variant: variants.addNewBtn, leftIcon: _jsx(AddIcon, {}), children: "Add New Site" })), _jsxs(Table, { children: [_jsx(Thead, { layerStyle: 'thead', children: _jsxs(Tr, { children: [_jsx(Td, { children: "Site" }), _jsx(Td, { children: "Primary address" }), _jsx(Td, { children: "Square Footage Under Management" }), _jsx(Td, {})] }) }), _jsx(Tbody, { children: renderRows() })] }), canEdit && (_jsxs(_Fragment, { children: [_jsx(AddSiteModal, { isOpen: isOpenAddSite, onClose: onCloseAddSite, org: organizationDetails }), siteToEdit && (_jsx(EditSiteModal, { siteToEdit: siteToEdit, isOpen: isOpenEditSite, onClose: () => {
                            onCloseEditSite();
                            setSiteToEdit(undefined);
                        }, orgIdentity: selectedOrganizationIdentity }))] }))] }));
};
