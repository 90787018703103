import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
export const NavLink = ({ children, href, external, }) => {
    if (external) {
        return _jsx(ChakraLink, { href: href, children: children });
    }
    else if (href) {
        return _jsx(ReactLink, { to: href, children: children });
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
