import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text } from '@chakra-ui/react';
import { OnHoverWrapper } from '@frontend/design-system/components/Inputs/OnHoverWrapper';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { SettingsEditDeleteBtnGroup } from './SettingsEditDeleteBtnGroup';
import { SettingsInput } from './SettingsInput';
import { SettingsSaveCancelBtnGroup } from './SettingsSaveCancelBtnGroup';
export const FundingTypeInput = ({ canEdit, pageMode, originalData, onDelete, validateInput, onSave, setPageMode, }) => {
    const [hoverMode, setHoverMode] = useState(false);
    const [validationError, setValidationError] = useState(undefined);
    const [fundingInput, setFundingInput] = useState(originalData);
    const [isSelected, setIsSelected] = useState(false);
    const FUNDING_TYPE_NAME_ERROR_MSG = 'Funding Type name must be unique';
    const onInputChange = (e) => {
        validateInput(e.target.value, () => setValidationError(FUNDING_TYPE_NAME_ERROR_MSG), () => setValidationError(undefined));
        setFundingInput(Object.assign(Object.assign({}, fundingInput), { name: e.target.value }));
    };
    const onEdit = () => {
        setIsSelected(true);
        setPageMode(SettingsPageMode.EDIT);
    };
    const onCancel = () => {
        setPageMode(SettingsPageMode.VIEW);
        setValidationError(undefined);
        setFundingInput(originalData);
        setIsSelected(false);
    };
    return (_jsxs(Flex, { flexDirection: 'column', children: [_jsxs(OnHoverWrapper, { canEdit: canEdit && pageMode === SettingsPageMode.VIEW, setHoverMode: setHoverMode, customStyles: {
                    justifyContent: 'space-between',
                }, children: [(pageMode === SettingsPageMode.EDIT && isSelected) ||
                        (pageMode === SettingsPageMode.ADD_NEW && !originalData) ? (_jsxs(_Fragment, { children: [_jsx(SettingsInput, { validationError: validationError, placeholder: 'Funding Type', data: fundingInput ? fundingInput.name : '', onChange: onInputChange, characterLimit: 30 }), _jsx(SettingsSaveCancelBtnGroup, { btnLabel: 'Funding Type', validationError: validationError, data: fundingInput, onSave: () => {
                                    if (fundingInput) {
                                        onSave(fundingInput).then((response) => {
                                            if (response) {
                                                setIsSelected(false);
                                            }
                                        });
                                    }
                                }, onCancel: onCancel })] })) : (_jsx(Text, { children: originalData === null || originalData === void 0 ? void 0 : originalData.name })), hoverMode &&
                        originalData &&
                        pageMode === SettingsPageMode.VIEW &&
                        canEdit && (_jsx(SettingsEditDeleteBtnGroup, { btnLabel: 'Funding Type', data: originalData, onEdit: onEdit, onDelete: onDelete, disableDelete: originalData.isInUse }))] }), validationError && (_jsx(Text, { mt: '5px', color: 'red', fontSize: '12px', children: validationError }))] }));
};
