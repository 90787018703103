import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Td, Tr, } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { appColors } from '../../../config/constants';
export const SystemAdminRow = ({ children, popoverMenu, }) => {
    const renderActionCol = () => {
        return (_jsx(Td, { w: '10em', children: _jsxs(Popover, { placement: 'left', children: [_jsx(PopoverTrigger, { children: _jsx(IconButton, { boxSize: '25px', icon: _jsx(BsThreeDots, {}), "aria-label": 'More Menu', variant: 'ghost' }) }), _jsx(PopoverContent, { width: 'auto', border: '1px solid #E6E7E9', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)', bg: appColors.BACKGROUND_LIGHT_GRAY, children: _jsx(PopoverBody, { children: popoverMenu }) })] }) }));
    };
    return (_jsx(_Fragment, { children: _jsxs(Tr, { children: [children, renderActionCol()] }) }));
};
