var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import services from '..';
export const usePlatformUserApi = () => {
    const sendInvite = (user) => __awaiter(void 0, void 0, void 0, function* () {
        return services.userService.sendInvite(user);
    });
    const configureUser = (user) => __awaiter(void 0, void 0, void 0, function* () {
        return services.userService.configureUser(user);
    });
    const viewUser = (userId) => __awaiter(void 0, void 0, void 0, function* () {
        return services.userService.viewUser(userId);
    });
    const resendInvite = (userId) => __awaiter(void 0, void 0, void 0, function* () {
        return services.userService.resendInvite(userId);
    });
    /** Deprecated
     * Should be updated with service layer and platform migration
     * https://dev.azure.com/SitelogIQ/MysiteIQ/_workitems/edit/4266
     */
    const deprecatedOrgUsersList = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return services.userService.orgUsersList(orgIdentity);
    });
    /** Deprecated
     * Should be updated with service layer and platform migration
     * https://dev.azure.com/SitelogIQ/MysiteIQ/_workitems/edit/4258
     */
    const deprecateGetUsersList = () => __awaiter(void 0, void 0, void 0, function* () {
        return services.userService.getUsersList();
    });
    const offboardUser = (userIdentity, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        if (orgIdentity) {
            return services.userService.offboardUser({
                users: [{ userId: userIdentity, organizationId: orgIdentity }],
            });
        }
        else {
            return services.userService.offboardUser({
                users: [{ userId: userIdentity }],
            });
        }
    });
    return {
        sendInvite,
        configureUser,
        viewUser,
        resendInvite,
        deprecatedOrgUsersList,
        deprecateGetUsersList,
        offboardUser,
    };
};
