import { jsx as _jsx } from "react/jsx-runtime";
import { List } from '@chakra-ui/react';
import { useState } from 'react';
import { TagCard } from './TagCard';
export const MyPhotosBody = ({ canEdit, orgIdentity, orgTags, getTags, }) => {
    const [selectedTag, setSelectedTag] = useState(undefined);
    const [showActions, setShowActions] = useState(false);
    return (_jsx(List, { spacing: 3, border: '1px solid #E6E7E9', borderRadius: '2px', p: '20px', height: 'calc(57vh)', overflow: 'auto', children: orgTags.length > 0 &&
            orgTags.map((tag) => {
                return (_jsx(TagCard, { canEdit: canEdit, orgIdentity: orgIdentity, tag: tag, orgTags: orgTags, selectedTag: selectedTag, setSelectedTag: setSelectedTag, showActions: showActions, setShowActions: setShowActions, getTags: getTags }));
            }) }));
};
