var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SystemRoleInput } from '../../../components/forms/SystemRoleInput';
import { TextInput } from '../../../components/forms/TextInput';
import { appColors } from '../../../config/constants';
import { SystemRoles, } from '../../../objects/UserData';
import { usePlatformUserApi } from '../../../services/api/platformUserApiHook';
export const AddAdminModal = ({ isOpen, onClose, }) => {
    const [roleId, setRoleId] = useState(undefined);
    const [roleMissing, setRoleMissing] = useState(false);
    const { sendInvite } = usePlatformUserApi();
    const { register, handleSubmit, formState: { errors }, reset, } = useForm();
    const toast = useToast();
    const firstNameRegister = register(FIRST_NAME_KEY, { required: true });
    const lastNameRegister = register(LAST_NAME_KEY, { required: true });
    const jobTitleRegister = register(JOB_TITLE_KEY, { required: true });
    const emailRegister = register(EMAIL_KEY, {
        pattern: /^\S+@\S+$/i,
        required: true,
    });
    const phoneNumberRegister = register(PHONE_NUMBER_KEY);
    useEffect(() => {
        if (roleId !== undefined) {
            setRoleMissing(false);
        }
    }, [roleId]);
    const renderError = (label) => {
        if (label === 'First Name' && errors.firstName) {
            return `Please enter ${label}.`;
        }
        if (label === 'Last Name' && errors.lastName) {
            return `Please enter ${label}.`;
        }
        if (label === 'Title' && errors.jobTitle) {
            return `Please enter ${label}.`;
        }
        if (label === 'Email' && errors.email) {
            return `Please enter ${label}.`;
        }
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (roleId !== undefined) {
            try {
                yield sendInvite({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    jobTitle: data.jobTitle,
                    phoneNumber: data.phoneNumber,
                    emailAddress: data.email,
                    systemRole: roleId,
                });
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
                onEnd();
            }
            catch (_a) {
                toast({
                    title: 'Save failed',
                    description: 'Please check that all fields are entered correctly.',
                    status: 'error',
                    isClosable: true,
                });
            }
        }
    });
    const runReset = () => {
        setRoleMissing(false);
        reset({
            firstName: '',
            lastName: '',
            jobTitle: '',
            email: '',
            phoneNumber: '',
        });
    };
    const onEnd = () => {
        setRoleId(undefined);
        runReset();
        onClose();
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onEnd, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent
            // TODO: FIX SPACING
            , { 
                // TODO: FIX SPACING
                minW: 741, h: 495, borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: "New Admin" }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { children: _jsxs(Stack, { py: 2, spacing: 5, children: [_jsx(SystemRoleInput, { label: 'Role', required: true, defaultValue: '', errorMsg: roleMissing ? 'Please select a Role.' : '', setRoleId: setRoleId, children: _jsx(_Fragment, { children: SystemRoles.map((role, i) => {
                                                return (_jsx("option", { value: role.roleName, children: role.role }, i));
                                            }) }) }), _jsx(TextInput, { label: 'First Name', register: firstNameRegister, required: true, errorMsg: renderError('First Name') }), _jsx(TextInput, { label: 'Last Name', register: lastNameRegister, required: true, errorMsg: renderError('Last Name') }), _jsx(TextInput, { label: 'Title', register: jobTitleRegister, required: true, errorMsg: renderError('Title') }), _jsx(TextInput, { label: 'Email', register: emailRegister, required: true, inputType: 'email', errorMsg: renderError('Email') }), _jsx(TextInput, { label: 'Phone', register: phoneNumberRegister, required: false, inputType: 'tel' })] }) }), _jsx(ModalFooter, { pt: 5, children: _jsx(Button, { type: 'submit', w: '100%', variant: variants.gradientBtn, onClick: () => {
                                    if (roleId === undefined) {
                                        setRoleMissing(true);
                                    }
                                }, children: "add" }) })] }) })] }));
};
const FIRST_NAME_KEY = 'firstName';
const LAST_NAME_KEY = 'lastName';
const JOB_TITLE_KEY = 'jobTitle';
const EMAIL_KEY = 'email';
const PHONE_NUMBER_KEY = 'phoneNumber';
