import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, List } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { PrioritizationWeightInput } from './PrioritizationWeightInput';
export const PrioritizationAlgorithmCriteriaList = ({ currentTabIndex, prioritizationAlgorithms, algorithmCriteriaList, canEdit, pageMode, onRemove, acceptPrioritizations, setPageMode, criteriaOptions, priorityCriteria, }) => {
    const [algorithmIndexToAdd, setAlgorithmIndexToAdd] = useState(undefined);
    return (_jsx(_Fragment, { children: _jsxs(List, { spacing: 3, p: '20px', children: [algorithmCriteriaList.length &&
                    algorithmCriteriaList.map((criteria, index) => {
                        return (_jsx(PrioritizationWeightInput, { canEdit: canEdit, pageMode: pageMode, originalData: criteria, onRemove: onRemove, prioritizationAlgorithms: prioritizationAlgorithms, algorithmCriteriaList: algorithmCriteriaList, acceptPrioritizations: acceptPrioritizations, setPageMode: setPageMode, currentTabIndex: currentTabIndex, criteriaOptions: criteriaOptions, setAlgorithmIndexToAdd: setAlgorithmIndexToAdd, priorityCriteria: priorityCriteria }, `criteria-${index}`));
                    }), pageMode === SettingsPageMode.ADD_NEW &&
                    algorithmIndexToAdd === currentTabIndex && (_jsx(_Fragment, { children: _jsx(PrioritizationWeightInput, { canEdit: canEdit, pageMode: pageMode, onRemove: onRemove, prioritizationAlgorithms: prioritizationAlgorithms, algorithmCriteriaList: algorithmCriteriaList, acceptPrioritizations: acceptPrioritizations, setPageMode: setPageMode, currentTabIndex: currentTabIndex, criteriaOptions: criteriaOptions, setAlgorithmIndexToAdd: setAlgorithmIndexToAdd, priorityCriteria: priorityCriteria }) })), canEdit && (_jsx(Button, { "data-testid": 'add-new-prioritization', onClick: () => {
                        setPageMode(SettingsPageMode.ADD_NEW);
                        setAlgorithmIndexToAdd(currentTabIndex);
                    }, isDisabled: algorithmCriteriaList.length >= 10 ||
                        pageMode === SettingsPageMode.ADD_NEW, leftIcon: _jsx(AddIcon, {}), variant: variants.detailsInputText, textTransform: 'none', pl: '0px', children: "Add new" }))] }) }));
};
