import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text } from '@chakra-ui/react';
import { ContainerWithHeader } from '@frontend/design-system/components/DataDisplay/ContainerWithHeader';
import { useEffect, useState } from 'react';
import { EscalationFactorNumberInput } from './EscalationFactorNumberInput';
export const EscalationFactorContainer = ({ orgEscalationFactor, generalUpdateEscalationFactor, canEdit, }) => {
    const [escalationFactorInput, setEscalationFactorInput] = useState(undefined);
    const formatNumber = (num) => {
        // we need to only display the decimal if there is value in it 5.0 -> 5 and 5.1 - 5.1
        const formattedNumber = Math.round(num * 10) / 10;
        return formattedNumber.toString();
    };
    useEffect(() => {
        if (orgEscalationFactor) {
            setEscalationFactorInput({
                escalation: formatNumber(orgEscalationFactor.escalation * 100),
            });
        }
        else {
            setEscalationFactorInput(undefined);
        }
    }, [orgEscalationFactor]);
    const handleSave = () => {
        if (escalationFactorInput) {
            generalUpdateEscalationFactor(escalationFactorInput);
        }
    };
    const onChangeInput = (value) => {
        let formattedValue = value;
        // Any letters left over from 'None' are removed as well as - and + characters.
        formattedValue = value.replace(/[a-zA-Z\-+]/g, '');
        // Remove leading zeros before whole numbers but preserve decimals
        formattedValue = formattedValue.replace(/(?:\b|\D)0+(\d+)/g, '$1');
        if (parseInt(formattedValue) >= 100) {
            formattedValue = '100';
        }
        if (formattedValue === '0' ||
            formattedValue === '0.0' ||
            formattedValue === '') {
            if ((escalationFactorInput === null || escalationFactorInput === void 0 ? void 0 : escalationFactorInput.escalation) === 'None') {
                formattedValue = '1';
            }
            else {
                formattedValue = 'None';
            }
        }
        setEscalationFactorInput({
            escalation: formattedValue,
        });
    };
    const escalationIsValid = (value) => {
        if (value === 'None') {
            return true;
        }
        // Check if the value matches the pattern of either whole number or decimal with at most one digit after the decimal point
        return value ? /^(?:\d+(\.\d?)?|\.?\d)$/.test(value) : false;
    };
    return (_jsx(ContainerWithHeader, { header: { label: 'Escalation Factor' }, children: _jsxs(Flex, { justifyContent: 'center', border: '1px solid #E6E7E9', borderRadius: '2px', px: '20px', flexDirection: 'column', onMouseLeave: () => {
                if (escalationIsValid(escalationFactorInput === null || escalationFactorInput === void 0 ? void 0 : escalationFactorInput.escalation)) {
                    handleSave();
                }
            }, onTouchEnd: () => {
                if (escalationIsValid(escalationFactorInput === null || escalationFactorInput === void 0 ? void 0 : escalationFactorInput.escalation)) {
                    handleSave();
                }
            }, children: [_jsx(Box, { mx: 'auto', mt: '25px', mb: escalationIsValid(escalationFactorInput === null || escalationFactorInput === void 0 ? void 0 : escalationFactorInput.escalation)
                        ? '25px'
                        : '5px', children: _jsx(EscalationFactorNumberInput, { canEdit: canEdit, data: (escalationFactorInput === null || escalationFactorInput === void 0 ? void 0 : escalationFactorInput.escalation) || 'None', onChangeInput: onChangeInput, min: 0, max: 100 }) }), escalationFactorInput &&
                    !escalationIsValid(escalationFactorInput === null || escalationFactorInput === void 0 ? void 0 : escalationFactorInput.escalation) && (_jsx(Text, { textAlign: 'center', color: 'red', fontSize: '12px', children: "There is a maximum of one decimal place" }))] }) }));
};
