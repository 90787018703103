import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { UserManagementContext } from '@frontend/domain/contexts/Authorization/Roles';
import { useManageOrganizationPermissions, useManageUserPermissions, } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { PlannerSettingsProvider } from '@frontend/domain/contexts/Settings/PlannerSettingsProvider';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { useEffect, useState } from 'react';
import { useReports } from '../../components/context/ReportsContext';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { usePlatformUserApi } from '../../services/api/platformUserApiHook';
import { useAppDispatch } from '../../store/hooks';
import { fetchStates, fetchVerticalMarkets } from '../../store/lookupsSlice';
import { fetchPlatformSitesByOrgId } from '../../store/orgSlice';
import { ReportTable } from './Admin/ReportTable';
import { MyFacilitiesTab } from './Facilities/MyFacilitiesTab';
import { GeneralInput } from './Organization/GeneralInput';
import { MyPlanningPage } from './Planning/MyPlanning';
import { MyPhotosTab } from './Tags/MyPhotosTab';
import { UsersTable } from './Users/UsersTable';
export const OrganizationDetailsPage = ({ isCreateMode = false, }) => {
    const { selectedOrganizationIdentity, onSelectOrganization, onUpdateOrganization, } = useOrganizationSummaries();
    const { user: currentUser } = useCurrentUser();
    const { deprecatedCanAddLocalAdmin, canResendInvite } = useManageUserPermissions();
    const { canEditAppAccess } = useManageOrganizationPermissions();
    const [orgUsers, setOrgUsers] = useState([]);
    const { deprecatedOrgUsersList } = usePlatformUserApi();
    /** Deprecated
     * Should be updated with service layer and platform migration
     * https://dev.azure.com/SitelogIQ/MysiteIQ/_workitems/edit/4266
     */
    const getOrgUsersList = () => {
        if (selectedOrganizationIdentity) {
            deprecatedOrgUsersList({
                organizationId: selectedOrganizationIdentity,
            }).then((usersList) => {
                usersList &&
                    setOrgUsers(filterUsers(usersList, selectedOrganizationIdentity));
            });
        }
    };
    /** Runs onUpdateOrganization for org list as well as fetches list of users
     * Need to fetch list of users to update any changed app permissions
     */
    const onOrganizationUpdate = (org, orgIdentity) => {
        onUpdateOrganization(org, orgIdentity);
        getOrgUsersList();
    };
    const { organizationDetails, upsertOrganization } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
        onUpdateOrganization: onOrganizationUpdate,
    });
    const { selectReport } = useReports();
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal, } = useDisclosure();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchVerticalMarkets());
        dispatch(fetchStates());
    }, []);
    useEffect(() => {
        getOrgUsersList();
        if (selectedOrganizationIdentity) {
            dispatch(fetchPlatformSitesByOrgId(selectedOrganizationIdentity));
        }
    }, [selectedOrganizationIdentity]);
    const renderDetails = () => {
        const { canEdit: canEditOrg, canManagePhotoTags, canSeeAdminOrgSettings, canAdd: canAddOrg, } = useManageOrganizationPermissions();
        const { canAdd, canEdit: canEditUser } = useManageUserPermissions();
        const canAddOrgUser = canAdd(UserManagementContext.ORG, selectedOrganizationIdentity);
        const canEditOrgUser = canEditUser(UserManagementContext.ORG, selectedOrganizationIdentity);
        return (_jsx(_Fragment, { children: _jsxs(Tabs, { w: '100%', h: '100%', isLazy: true, children: [_jsxs(TabList, { mb: '15px', children: [_jsx(Tab, { children: _jsx("b", { children: "myOrganization" }) }), !isCreateMode && selectedOrganizationIdentity && (_jsxs(_Fragment, { children: [_jsx(Tab, { children: _jsx("b", { children: "myFacilities" }) }), canManagePhotoTags && (_jsx(Tab, { children: _jsx("b", { children: "myGallery" }) })), _jsx(Tab, { children: _jsx("b", { children: "myPlanning" }) }), _jsx(Tab, { children: _jsx("b", { children: "myUsers" }) }), canSeeAdminOrgSettings && (_jsx(Tab, { children: _jsx("b", { children: "Admin" }) }))] }))] }), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(GeneralInput, { orgUsers: orgUsers, canEdit: canEditOrg || canAddOrg, currentUser: currentUser, canAddLocalAdmin: deprecatedCanAddLocalAdmin, canEditAppContext: canEditAppAccess, org: isCreateMode
                                        ? undefined
                                        : organizationDetails, onSelectOrganization: onSelectOrganization, isCreateMode: isCreateMode, upsertOrganization: upsertOrganization }) }), !isCreateMode && selectedOrganizationIdentity && (_jsx(TabPanel, { children: _jsx(MyFacilitiesTab, { canEdit: canEditOrg }) })), !isCreateMode &&
                                canManagePhotoTags &&
                                organizationDetails && (_jsx(TabPanel, { children: _jsx(MyPhotosTab, { org: organizationDetails, canEdit: canEditOrg }) })), !isCreateMode && selectedOrganizationIdentity && (_jsx(TabPanel, { children: _jsx(PlannerSettingsProvider, { orgIdentity: selectedOrganizationIdentity, children: _jsx(MyPlanningPage, { canEdit: canEditOrg }) }) })), !isCreateMode && selectedOrganizationIdentity && (_jsx(TabPanel, { children: _jsx(UsersTable, { org: organizationDetails, orgUsers: orgUsers, getOrgUsersList: getOrgUsersList, canEditOrgUser: canEditOrgUser, canAddOrgUser: canAddOrgUser, currentUser: currentUser, canResendInvite: canResendInvite }) })), !isCreateMode &&
                                canSeeAdminOrgSettings &&
                                selectedOrganizationIdentity && (_jsx(TabPanel, { children: _jsxs(_Fragment, { children: [_jsxs(Flex, { mb: '20px', justifyContent: 'space-between', children: [_jsx(Heading, { as: 'h4', size: 'md', fontWeight: 600, color: appColors.BLACK, children: "Report Assignment" }), _jsx(Button, { variant: variants.transparentBlueBtn, leftIcon: _jsx(AddIcon, { w: '12px', h: '12px' }), borderRadius: '18px', fontSize: '14px', onClick: () => {
                                                        selectReport(undefined);
                                                        onOpenModal();
                                                    }, children: "Add New" })] }), _jsx(ReportTable, { orgIdentity: selectedOrganizationIdentity, onOpen: onOpenModal, isOpen: isOpenModal, onClose: onCloseModal })] }) }))] })] }) }));
    };
    return (
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    _jsx(PageLayout, { title: !isCreateMode
            ? organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.name
            : 'Create Organization', hideOrgsDropdown: isCreateMode, hideAddOrgButton: isCreateMode, children: renderDetails() }));
};
export const filterUsers = (usersList, orgIdentity) => {
    return usersList.filter((user) => {
        var _a;
        return (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => orgRole.organizationIdentity === orgIdentity);
    });
};
