var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useForm } from 'react-hook-form';
import { appColors } from '../../../config/constants';
import { addSiteToOrg } from '../../../services/api/orgDetailsAPI';
import { useAppDispatch } from '../../../store/hooks';
import { fetchPlatformSitesByOrgId } from '../../../store/orgSlice';
export const AddSiteModal = ({ isOpen, onClose, org, }) => {
    const { register, handleSubmit, formState: { errors, isSubmitting }, reset, } = useForm();
    const dispatch = useAppDispatch();
    const toast = useToast();
    const nameRegister = register(NAME_KEY, { required: true });
    const addressRegister = register(ADDRESS_KEY, { required: true });
    const cityRegister = register(CITY_KEY, { required: true });
    const stateRegister = register(STATE_KEY, { required: true });
    const zipRegister = register(ZIP_KEY, { required: true });
    const renderInput = (label, register, inputType) => {
        return (_jsxs(FormControl, { children: [_jsxs(HStack, { children: [_jsx(FormLabel, { mr: '3px', children: label }), _jsx("p", { style: {
                                color: 'red',
                                marginLeft: '0',
                                marginBottom: '9px',
                            }, children: "*" })] }), _jsx(Input, Object.assign({ type: inputType ? inputType : 'text', placeholder: label }, register)), renderError(label)] }));
    };
    const renderError = (label) => {
        var _a, _b, _c, _d;
        if (label === 'Site' && errors.name) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
        if (label === 'Primary Address' && ((_a = errors.address) === null || _a === void 0 ? void 0 : _a.address1)) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
        if (label === 'State' && ((_b = errors.address) === null || _b === void 0 ? void 0 : _b.state)) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
        if (label === 'City' && ((_c = errors.address) === null || _c === void 0 ? void 0 : _c.city)) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
        if (label === 'Zip' && ((_d = errors.address) === null || _d === void 0 ? void 0 : _d.zip)) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (org)
                yield addSiteToOrg(org.id, {
                    name: data.name,
                    address: {
                        address1: data.address.address1,
                        city: data.address.city,
                        state: data.address.state,
                        zip: data.address.zip,
                    },
                });
            toast({
                title: 'Saved',
                status: 'success',
                isClosable: true,
            });
            org && dispatch(fetchPlatformSitesByOrgId(org.identity));
            onClose();
            runReset();
        }
        catch (_a) {
            toast({
                title: 'Save failed',
                description: 'Please check that all fields are entered correctly.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    const runReset = () => {
        reset({
            name: '',
            address: {
                address1: '',
                city: '',
                state: '',
                zip: '',
            },
        });
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => {
            onClose();
            runReset();
        }, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent
            // TODO: FIX SPACING
            , { 
                // TODO: FIX SPACING
                minW: 741, h: 495, borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: "New Site" }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { children: _jsxs(Stack, { py: 2, spacing: 5, children: [renderInput('Site', nameRegister), _jsxs(HStack, { children: [renderInput('Primary Address', addressRegister), renderInput('State', stateRegister)] }), _jsxs(HStack, { children: [renderInput('City', cityRegister), renderInput('Zip', zipRegister)] })] }) }), _jsx(ModalFooter, { pt: 5, children: _jsx(Button, { type: 'submit', w: '100%', variant: variants.gradientBtn, isDisabled: isSubmitting, children: "add site" }) })] }) })] }));
};
const NAME_KEY = 'name';
const ADDRESS_KEY = 'address.address1';
const CITY_KEY = 'address.city';
const STATE_KEY = 'address.state';
const ZIP_KEY = 'address.zip';
