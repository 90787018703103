var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var _a;
import { BlobServiceClient } from '@azure/storage-blob';
const containerName = 'dev-images';
const sasToken = (_a = process.env.REACT_APP_BLOB_SAS_TOKEN) !== null && _a !== void 0 ? _a : '';
const storageAccountName = 'mysiteiqstorage';
export const uploadFileToBlob = (file) => __awaiter(void 0, void 0, void 0, function* () {
    if (!file)
        return [];
    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net/?${sasToken}`);
    // get Container - full public read access
    const containerClient = blobService.getContainerClient(containerName);
    // await containerClient.createIfNotExists({
    //   access: 'container',
    // });
    // upload file
    yield createBlobInContainer(containerClient, file);
    // get list of blobs in container
    return getBlobsInContainer(containerClient);
});
const getBlobsInContainer = (containerClient) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, e_1, _c, _d;
    const returnedBlobUrls = [];
    try {
        // get list of blobs in container
        // eslint-disable-next-line
        for (var _e = true, _f = __asyncValues(containerClient.listBlobsFlat()), _g; _g = yield _f.next(), _b = _g.done, !_b; _e = true) {
            _d = _g.value;
            _e = false;
            const blob = _d;
            // if image is public, just construct URL
            returnedBlobUrls.push(`https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (!_e && !_b && (_c = _f.return)) yield _c.call(_f);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return returnedBlobUrls;
});
const createBlobInContainer = (containerClient, file) => __awaiter(void 0, void 0, void 0, function* () {
    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(file.name);
    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    // upload file
    yield blobClient.uploadData(file, options);
});
