var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import services from '..';
export const usePlatformOrgApi = () => {
    const getPlatformSitesByOrgId = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.getPlatformSitesByOrgId(orgIdentity);
    });
    const userReports = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.userReports(orgIdentity);
    });
    const assessmentRefresh = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.assessmentRefresh(orgIdentity);
    });
    const fetchReportToken = (reportIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.fetchReport(reportIdentity);
    });
    const registerReport = (data) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.registerReport(data);
    });
    const removeReport = (reportId) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.removeReport(reportId);
    });
    return {
        userReports,
        assessmentRefresh,
        fetchReportToken,
        registerReport,
        removeReport,
        getPlatformSitesByOrgId,
    };
};
