import { environment } from './config/environment';

export const authConfig = {
	domain: environment.apiAuthDomain,
	clientId: environment.apiAuthClientId,
	authorizationParams: {
		redirectUri: environment.apiAuthRedirectUri,
		audience: environment.apiPlatformAudience,
	},
};
