import { jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export const ErrorLabel = ({ label }) => {
    return (_jsxs("p", { style: {
            color: 'red',
            paddingTop: 1,
            paddingLeft: 1,
            fontSize: 'sm',
        }, children: ["Please enter ", label, "."] }));
};
