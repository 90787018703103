import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { appValues } from '../config/constants';
export function LogoutPage() {
    const { logout } = useAuth0();
    useEffect(() => {
        logout({
            logoutParams: {
                federated: true,
                returnTo: window.location.origin,
            },
        });
    }, []);
    return _jsx(Flex, { h: `calc(100vh - ${appValues.FOOTER_HEIGHT}px)` });
}
