var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class SiteService {
    constructor(api) {
        this.api = api;
    }
    updateSite(id, site) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`/sites/${id}`, site);
        });
    }
    addFacilityToSite(sid, newFacility) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.post(`/sites/${sid}/facilities`, newFacility);
        });
    }
    uploadFacilitiesFromCSV(sid, file) {
        return __awaiter(this, void 0, void 0, function* () {
            const body = new FormData();
            body.append('file', file);
            return yield this.api.postFormData(`/sites/${sid}/upload/facilities`, body);
        });
    }
    updateFacility(id, facility) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`/facilities/${id}`, facility);
        });
    }
}
