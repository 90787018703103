import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useManageOrganizationPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useNavigate } from 'react-router-dom';
import { appValues } from '../../config/constants';
import { PageHeader } from '../../sharedComponents/PageHeader';
import { ExpandableSidebar } from '../navigation/ExpandableSidebar';
export const PageLayout = ({ title, children, hideOrgsDropdown, hideAddOrgButton, }) => {
    const { organizations, onSelectOrganization, selectedOrganizationIdentity, isLoading, } = useOrganizationSummaries();
    // TODO: might not be needed after state management changes
    const { canAdd } = useManageOrganizationPermissions();
    const navigate = useNavigate();
    const showAddOrgButton = !hideAddOrgButton && canAdd ? true : false;
    return (_jsx(ExpandableSidebar, { children: _jsxs(Box, { m: '0px 50px', children: [_jsxs(HStack, { children: [_jsx(PageHeader, { title: title !== null && title !== void 0 ? title : '', hideOrgsDropdown: hideOrgsDropdown, organizations: organizations, isLoading: isLoading, selectedOrganizationIdentity: selectedOrganizationIdentity, onSelectOrganization: onSelectOrganization }), showAddOrgButton && (_jsx(Button, { onClick: () => {
                                navigate(AppRoutes.createOrganization());
                            }, w: '250px', minW: '118', h: '40px', variant: variants.transparentBlueBtn, leftIcon: _jsx(AddIcon, {}), overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', children: "New Organization" }))] }), _jsx(Flex, { flexGrow: 1, flexDir: 'column', h: `calc(100vh - ${appValues.HEADER_HEIGHT}px)`, overflowY: 'auto', children: children })] }) }));
};
