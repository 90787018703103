import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageLayout } from '@/components/layouts/PageLayout';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useManageUserPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import React, { useState } from 'react';
import { usePlatformUserApi } from '../../services/api/platformUserApiHook';
import { SystemAdmin } from './SystemAdmin/SystemAdmin';
import { UserManagement } from './UserManagement/UserManagement';
export var TableType;
(function (TableType) {
    TableType["org"] = "Org Management";
    TableType["user"] = "User Management";
    TableType["systemAdmin"] = "System Admin";
})(TableType || (TableType = {}));
export const SystemAdminPage = () => {
    const { organizations } = useOrganizationSummaries();
    const [users, setUsers] = useState();
    const { deprecateGetUsersList } = usePlatformUserApi();
    const { user: currentUser } = useCurrentUser();
    const { canResendInvite } = useManageUserPermissions();
    /** Deprecated
     * Should be updated with service layer and platform migration
     * https://dev.azure.com/SitelogIQ/MysiteIQ/_workitems/edit/4258
     */
    const getUpdatedUsersList = () => {
        deprecateGetUsersList().then((users) => setUsers(users));
    };
    if (!users) {
        getUpdatedUsersList();
    }
    return (_jsx(PageLayout, { title: 'System Admin', hideOrgsDropdown: true, children: _jsxs(Tabs, { children: [_jsxs(TabList, { children: [_jsx(Tab, { children: "User Management" }), _jsx(Tab, { children: "System Admin" })] }), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(UserManagement, { currentUser: currentUser, users: users || [], onUserUpdate: getUpdatedUsersList, canResendInvite: canResendInvite, orgs: organizations }) }), _jsx(TabPanel, { children: _jsx(SystemAdmin, { currentUser: currentUser, users: users || [], onUserUpdate: getUpdatedUsersList, canResendInvite: canResendInvite }) })] })] }) }));
};
