import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
const defaultStyle = {
    fontWeight: '600',
    fontSize: '20px',
};
export const ContainerWithHeader = ({ header, children, style, htmlFor, }) => {
    return (_jsxs(Box, { width: '100%', children: [_jsxs(HStack, { justifyContent: 'space-between', alignItems: 'flex-start', mb: '20px', spacing: 10, style: style, children: [_jsx("label", { htmlFor: htmlFor, style: Object.assign(Object.assign({ height: '36px' }, defaultStyle), header.style), children: header.label }), header.button] }), _jsx(Box, { width: '100%', position: 'relative', children: children })] }));
};
