import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Td, Tr, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { appColors } from '../../../config/constants';
import { OrganizationRoleEnum, } from '../../../objects/UserData';
import { UserApps } from '../../../sharedComponents/UserApps';
export const UsersTableRow = ({ user, org, orgRole, facilityRole, popoverMenu, canEditOrgUser, }) => {
    const [facilityName, setFacilityName] = useState('');
    const [siteName, setSiteName] = useState('');
    useEffect(() => {
        getFacility();
        getSite();
    }, [user]);
    const renderActionTd = () => {
        return (_jsxs(Popover, { placement: 'left', children: [_jsx(PopoverTrigger, { children: _jsx(IconButton, { boxSize: '25px', icon: _jsx(BsThreeDots, {}), "aria-label": 'More Menu', variant: 'ghost' }) }), _jsx(PopoverContent, { width: '150px', border: '1px solid', borderColor: appColors.BORDER_SEC_LIGHT_GRAY, boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)', bg: appColors.BACKGROUND_LIGHT_GRAY, children: _jsx(PopoverBody, { children: popoverMenu }) })] }));
    };
    const getSite = () => {
        // TODO: orgRole check should be moved out from this component
        if ((orgRole === null || orgRole === void 0 ? void 0 : orgRole.role) !== OrganizationRoleEnum.LOCAL_ADMIN &&
            (orgRole === null || orgRole === void 0 ? void 0 : orgRole.role) !== OrganizationRoleEnum.CLIENT_CONCIERGE &&
            (facilityRole === null || facilityRole === void 0 ? void 0 : facilityRole.facilityIdentity)) {
            org === null || org === void 0 ? void 0 : org.sites.forEach((site) => {
                site.facilities.length > 0 &&
                    site.facilities.forEach((facility) => {
                        if (facility.identity === (facilityRole === null || facilityRole === void 0 ? void 0 : facilityRole.facilityIdentity)) {
                            setSiteName(site.name);
                        }
                    });
            });
        }
    };
    const getFacility = () => {
        // TODO: orgRole check should be moved out from this component
        if ((orgRole === null || orgRole === void 0 ? void 0 : orgRole.role) !== OrganizationRoleEnum.LOCAL_ADMIN &&
            (orgRole === null || orgRole === void 0 ? void 0 : orgRole.role) !== OrganizationRoleEnum.CLIENT_CONCIERGE &&
            (facilityRole === null || facilityRole === void 0 ? void 0 : facilityRole.facilityIdentity)) {
            org === null || org === void 0 ? void 0 : org.sites.forEach((site) => {
                site.facilities.length > 0 &&
                    site.facilities.forEach((facility) => {
                        if (facility.identity === (facilityRole === null || facilityRole === void 0 ? void 0 : facilityRole.facilityIdentity)) {
                            setFacilityName(facility.name);
                        }
                    });
            });
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Tr, { h: '50px', boxSizing: 'border-box', children: [_jsxs(Td, { py: 0, children: [user.firstName, " ", user.lastName] }), _jsx(Td, { py: 0, children: user.jobTitle }), _jsx(Td, { children: siteName }), _jsx(Td, { children: facilityName }), _jsx(Td, { py: 0, children: user.email }), _jsx(Td, { py: 0, children: _jsx(UserApps, { user: user, org: org }) }), canEditOrgUser && _jsx(Td, { py: 0, children: renderActionTd() })] }) }));
};
