import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@chakra-ui/react';
import { OnHoverWrapper } from '@frontend/design-system/components/Inputs/OnHoverWrapper';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { SettingsEditDeleteBtnGroup } from './SettingsEditDeleteBtnGroup';
import { SettingsInput } from './SettingsInput';
import { SettingsSaveCancelBtnGroup } from './SettingsSaveCancelBtnGroup';
export const FacilityGrouperInput = ({ canEdit, pageMode, originalData, onSave, setPageMode, }) => {
    const [hoverMode, setHoverMode] = useState(false);
    const [facilityGrouperInput, setFacilityGrouperInput] = useState(originalData);
    const [isSelected, setIsSelected] = useState(false);
    const onInputChange = (e) => {
        setFacilityGrouperInput(Object.assign(Object.assign({}, facilityGrouperInput), { name: e.target.value }));
    };
    const onEdit = () => {
        setIsSelected(true);
        setPageMode(SettingsPageMode.EDIT);
    };
    const onCancel = () => {
        setPageMode(SettingsPageMode.VIEW);
        setFacilityGrouperInput(originalData);
        setIsSelected(false);
    };
    return (_jsxs(OnHoverWrapper, { canEdit: canEdit && pageMode === SettingsPageMode.VIEW, setHoverMode: setHoverMode, customStyles: { justifyContent: 'space-between' }, children: [pageMode === SettingsPageMode.EDIT && isSelected ? (_jsxs(_Fragment, { children: [_jsx(SettingsInput, { placeholder: 'Facility Grouper', data: facilityGrouperInput.name, onChange: onInputChange, characterLimit: 30 }), _jsx(SettingsSaveCancelBtnGroup, { btnLabel: 'Facility Grouper', data: facilityGrouperInput, onSave: () => {
                            if (facilityGrouperInput) {
                                onSave(facilityGrouperInput).then((response) => {
                                    if (response) {
                                        setIsSelected(false);
                                    }
                                });
                            }
                        }, onCancel: onCancel })] })) : (_jsx(Text, { children: originalData === null || originalData === void 0 ? void 0 : originalData.name })), hoverMode &&
                originalData &&
                pageMode === SettingsPageMode.VIEW &&
                canEdit && (_jsx(SettingsEditDeleteBtnGroup, { btnLabel: 'Facility Grouper', data: originalData, onEdit: onEdit, displayDelete: false }))] }));
};
