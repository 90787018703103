import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AddIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Td, Text, Tooltip, Tr, useDisclosure, } from '@chakra-ui/react';
import { Loading } from '@frontend/design-system/components/Loading/Loading';
import { variants } from '@frontend/design-system/theme/theme';
import { IVerticalMarketNames } from '@frontend/domain/models/Organization/OrganizationInterfaces';
import { lazy, Suspense, useEffect, useState } from 'react';
import { EditIcon2 } from '../../../config/icons';
import { InfoWarningModal } from '../../../sharedComponents/InfoWarningModal';
import { FacilityAdditionTable } from './FacilityAddition/FacilityAdditionTable';
const AddFacilityAdditionModal = lazy(() => import('./FacilityAddition/AddFacilityAdditionModal'));
export const FacilitiesTableRow = ({ facility, siteName, siteId, orgVerticalMarket, canEdit, onOpenEditFacility, setFacilityToEdit, setFacilitySiteIdToEdit, orgIdentity, }) => {
    const [showAdditions, setShowAdditions] = useState(false);
    const [additionalSquareFootage, setAdditionalSquareFootage] = useState(0);
    const { isOpen: isOpenWarning, onClose: onCloseWarning } = useDisclosure();
    const { isOpen: isOpenAddFacilityAddition, onOpen: onOpenAddFacilityAddition, onClose: onCloseAddFacilityAddition, } = useDisclosure();
    useEffect(() => {
        if (facility && facility.additions && facility.additions.length > 0)
            setAdditionalSquareFootage(facility.additions.reduce((total, addition) => {
                var _a;
                const squareFootage = (_a = addition.squareFootage) !== null && _a !== void 0 ? _a : 0;
                return total + squareFootage;
            }, 0));
    }, [facility, facility.additions]);
    const renderActionTd = () => {
        return (_jsxs(HStack, { children: [canEdit && (_jsxs(_Fragment, { children: [_jsxs(Text, { cursor: 'pointer', w: '125px', color: '#003B5D', fontWeight: '600', onClick: onOpenAddFacilityAddition, children: [_jsx(AddIcon, {}), "New addition"] }), _jsx(Tooltip, { label: 'Edit facility', openDelay: 300, bg: 'brand.primBlue', children: _jsx(IconButton, { onClick: () => {
                                    setFacilityToEdit(facility);
                                    setFacilitySiteIdToEdit(siteId);
                                    onOpenEditFacility();
                                }, boxSize: '26px', icon: _jsx(EditIcon2, {}), "aria-label": 'edit facility', variant: variants.transparentIcoBtn }) })] })), _jsx(Tooltip, { label: showAdditions
                        ? 'Hide facility additions'
                        : 'Show facility additions', openDelay: 300, bg: 'brand.primBlue', children: _jsx(IconButton, { onClick: () => {
                            setShowAdditions(!showAdditions);
                        }, boxSize: '26px', icon: showAdditions ? (_jsx(ChevronUpIcon, {})) : (_jsx(ChevronDownIcon, {})), "aria-label": 'show facility additions', variant: variants.transparentIcoBtn }) }), canEdit && facility.facilityIdentity && facility.name && (_jsx(Suspense, { fallback: _jsx(Loading, {}), children: _jsx(AddFacilityAdditionModal, { facilityName: facility.name, facilityIdentity: facility.facilityIdentity, isOpen: isOpenAddFacilityAddition, onClose: onCloseAddFacilityAddition, orgIdentity: orgIdentity }) }))] }));
    };
    const getPropertySizeValue = (row) => {
        const { originalSquareFootage, keyCount, unitCount } = row;
        if (originalSquareFootage && originalSquareFootage > 0)
            return String(originalSquareFootage + additionalSquareFootage > 0
                ? originalSquareFootage + additionalSquareFootage
                : '');
        if (keyCount && keyCount > 0)
            return keyCount.toString();
        if (unitCount && unitCount > 0)
            return unitCount.toString();
        return '';
    };
    return (_jsxs(_Fragment, { children: [_jsx(InfoWarningModal, { isOpen: isOpenWarning, onClose: onCloseWarning, children: _jsxs(_Fragment, { children: [_jsx(Text, { children: "You are about to remove from this organization. Are you sure you want to continue?" }), _jsx(HStack, { justifyContent: 'space-between', children: _jsx(Button, { onClick: onCloseWarning, variant: variants.orangeTransparentBtn, w: '48%', children: "Cancel" }) })] }) }), _jsxs(Tr, { h: '50px', boxSizing: 'border-box', children: [_jsx(Td, { py: 0, children: facility.name }), _jsx(Td, { py: 0, children: siteName }), _jsxs(Td, { py: 0, children: [facility.latitude, " / ", facility.longitude] }), _jsx(Td, { py: 0, children: facility.address1 }), _jsx(Td, { py: 0, children: getPropertySizeValue(facility) }), orgVerticalMarket === IVerticalMarketNames.K12_SCHOOLS && (_jsx(Td, { py: 0, children: facility.numberOfOccupants
                            ? facility.numberOfOccupants
                            : '' })), _jsx(Td, { py: 0, children: renderActionTd() })] }), showAdditions && (_jsx(Tr, { children: _jsx(Td, { colSpan: 6, children: _jsx(FacilityAdditionTable, { facility: facility, canEdit: canEdit, orgIdentity: orgIdentity }) }) }))] }));
};
