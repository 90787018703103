import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider, } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export function Authorization({ authOptions, children }) {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate((appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname);
    };
    return (_jsx(Auth0Provider, Object.assign({}, authOptions, { onRedirectCallback: onRedirectCallback, children: children })));
}
